/**
 * @owner @Appboy/frontend-ix
 */

.parsley-errors() {
  color: @warning-red;
  font-weight: normal;
  font-family: @font-sailec-demi;
  font-size: 13px;
}

body,
#main #main-col .row {
  input[type="text"].parsley-error,
  input[type="number"].parsley-error,
  input[type="url"].parsley-error,
  input[type="email"].parsley-error,
  input[type="password"].parsley-error,
  textarea.parsley-error {
    border: 1px solid @warning-red;
    outline-width: 0;

    &:focus {
      .box-shadow(none);
    }
  }

  ul.parsley-errors-list {
    .parsley-errors();
    display: inline;
    padding: 0;
    margin: 0;

    li {
      .parsley-errors();
      padding: 2px;
      list-style-type: none;
    }
  }
  input[data-parsley-incomplete],
  textarea[data-parsley-incomplete] {
    &.parsley-error {
      border-color: @orange-coral;
    }
  }

  ul.parsley-errors-list {
    li {
      &.parsley-incomplete {
        color: @orange-coral;
      }
    }
  }
  .dummy-parsley {
    input {
      visibility: hidden;
      height: 0;
      padding: 0;
      margin: 0;
      width: 0;
    }
  }
}

select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
  // This exists to offset a default margin-bottom: 9px that bootstrap adds to inputs, but which we don't want to
  // push validation down
  & + .parsley-errors-list li {
    margin-top: -9px;
  }
}
// Textareas with overlay have no margin-bottom, so remove the negative margin-top on parsley errors
.textoverlay + textarea + ul.parsley-errors-list {
  li {
    margin-top: 0;
  }
}
// Apparently some templated fields (see Windows Universal Push) attach the error to the wrapper rather
// than the textarea; handle those too.
.textoverlay-wrapper.with-input + ul.parsley-errors-list {
  li {
    margin-top: -15px;
  }
}
.form-horizontal {
  input,
  textarea,
  select,
  .help-inline,
  .uneditable-input,
  .input-prepend,
  .input-append {
    // And this exists to undo it for places where bootstrap sets no margin below inputs
    & + .parsley-errors-list li {
      margin-top: 0px;
    }
  }
}
