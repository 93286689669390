@import "@braze/bootstrap-less/src/variables";
@import "helpers/colors";
@import "helpers/variables";

// Use this to cause text to not overflow and truncate itself with an ellipsis (...). If you also want it to stay on one
// line (such as for a title), you should also apply white-space: nowrap;
// As far as I can tell, this doesn't work on inline elements.
// This also won't show ellipses on multi-line text
// (http://stackoverflow.com/questions/6222616/with-css-use-for-overflowed-block-of-multi-lines/14248844#14248844)
.ellipsis-overflow() {
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
}

.line-clamp(@val) {
  .ellipsis-overflow();
  display: -webkit-box;
  box-orient: vertical;
  -webkit-box-orient: vertical;
  line-clamp: @val;
  -webkit-line-clamp: @val;
}

// Transformations missing from bootstrap's built-in mixins.less
.scaleX(@ratio) {
  -webkit-transform: scaleX(@ratio);
  -moz-transform: scaleX(@ratio);
  -ms-transform: scaleX(@ratio);
  -o-transform: scaleX(@ratio);
  transform: scaleX(@ratio);
}

.scaleY(@ratio) {
  -webkit-transform: scaleY(@ratio);
  -moz-transform: scaleY(@ratio);
  -ms-transform: scaleY(@ratio);
  -o-transform: scaleY(@ratio);
  transform: scaleY(@ratio);
}

.keyframes-widen(@from-scale, @to-scale) {
  @-webkit-keyframes widen {
    from {
      -webkit-transform: @from-scale;
    }

    to {
      -webkit-transform: @to-scale;
    }
  }

  @-moz-keyframes widen {
    from {
      -moz-transform: @from-scale;
    }

    to {
      -moz-transform: @to-scale;
    }
  }

  @-ms-keyframes widen {
    from {
      -ms-transform: @from-scale;
    }

    to {
      -ms-transform: @to-scale;
    }
  }

  @-o-keyframes widen {
    from {
      -o-transform: @from-scale;
    }

    to {
      -o-transform: @to-scale;
    }
  }

  @keyframes widen {
    from {
      transform: @from-scale;
    }

    to {
      transform: @to-scale;
    }
  }
}

.grabbable {
  cursor: grab;
  cursor: -webkit-grab;
  cursor: -moz-grab;
}

.grabbing {
  cursor: grabbing;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
}

.swirling-colors {
  @-webkit-keyframes swirling {
    0% {
      .filter(hue-rotate(0deg) saturate(2) invert(0));
    }

    50% {
      .filter(hue-rotate(360deg) saturate(8) invert(0.25));
    }

    100% {
      .filter(hue-rotate(0deg) saturate(2) invert(0));
    }
  }

  .animation(swirling linear 30s infinite);
}

marquee {
  font-family:
    Comic Sans MS,
    Comic Sans,
    sans !important;
}

.nineteen-ninety-nine {
  // Comic Sans...
  font-family:
    Comic Sans MS,
    Comic Sans,
    sans !important;

  .fa,
  &.fa,
  .fas,
  &.fas,
  .far,
  &.far,
  .fab,
  &.fab {
    font-family: "Font Awesome Free 5" !important;
  }

  &.section-name:before {
    content: url("https://www.wonder-tonic.com/geocitiesizer/images/hot.gif");
  }

  &.section-header span:before {
    content: url("https://www.wonder-tonic.com/geocitiesizer/images/point.gif");
  }

  &#main-icon {
    background-image: url("https://www.wonder-tonic.com/geocitiesizer/images/underconstruction.gif");
    background-size: auto;
  }
}

.button-gradient(@startColor, @midColor1, @midColor2, @endColor) {
  background-image: -webkit-linear-gradient(top, @startColor 0%, @midColor1 50%, @midColor2 50%, @endColor 100%);
  background-image: -moz-linear-gradient(top, @startColor 0%, @midColor1 50%, @midColor2 50%, @endColor 100%);
  background-image: -ms-linear-gradient(top, @startColor 0%, @midColor1 50%, @midColor2 50%, @endColor 100%);
  background-image: linear-gradient(top, @startColor 0%, @midColor1 50%, @midColor2 50%, @endColor 100%);
}

.bottom-gradient(@startColor, @endColor) {
  background-image: -webkit-linear-gradient(bottom, @startColor, @endColor);
  background-image: -moz-linear-gradient(bottom, @startColor, @endColor);
  background-image: -ms-linear-gradient(bottom, @startColor, @endColor);
  background-image: linear-gradient(to top, @startColor, @endColor);
}

.animation(@animation) {
  -webkit-animation: @animation;
  -moz-animation: @animation;
  -ms-animation: @animation;
  -o-animation: @animation;
  animation: @animation;
}

// Prefixed transition calls. Useful only if you're only transitioning one property. Also, only useful if the property
// you're transitioning has a prefixed property in all of webkit, moz and o.
.prefixed-transition(@property, @transition) {
  -webkit-transition: -webkit- @property @transition;
  -moz-transition: -moz- @property @transition;
  -o-transition: -o- @property @transition;
  transition: @property @transition;
}

// Example usage: .transition(~"margin-left, -webkit-transform, -moz-transform, -ms-transform, -o-transform, transform", .5s);
// The squiggle escapes the string so that LESS doesn't get upset. Note that you're going to have to include all of
// the prefixed properties for those properties that you're transitioning. This is annoying and wasteful, but LESS isn't
// very powerful and I don't want to write a bunch of JS in here to do string parsing, so it'll be annoying and wasteful
// until we switch to SCSS.
.transition (@property, @duration, @style: ease-in-out) {
  -webkit-transition-property: @property;
  -webkit-transition-duration: @duration;
  -webkit-transition-timing-function: @style;

  -moz-transition-property: @property;
  -moz-transition-duration: @duration;
  -moz-transition-timing-function: @style;

  -ms-transition-property: @property;
  -ms-transition-duration: @duration;
  -ms-transition-timing-function: @style;

  -o-transition-property: @property;
  -o-transition-duration: @duration;
  -o-transition-timing-function: @style;

  transition-property: @property;
  transition-duration: @duration;
  transition-timing-function: @style;
}

.transition-delay(@duration) {
  -webkit-transition-delay: @duration;
  transition-delay: @duration;
}

.transform-style(@style) {
  -webkit-transform-style: @style;
  -moz-transform-style: @style;
  -ms-transform-style: @style;
  -o-transform-style: @style;
  transform-style: @style;
}

.transform(@transformation) {
  -moz-transform: @transformation;
  -webkit-transform: @transformation;
  -ms-transform: @transformation;
  -o-transform: @transformation;
  transform: @transformation;
}

.filter(@filter) {
  -webkit-filter: @filter;
  -moz-filter: @filter;
  -ms-filter: @filter;
  -o-filter: @filter;
  filter: @filter;
}

// Makes an item look like it's inset in the page
.inset-display(@paddingWidth) {
  padding-right: @paddingWidth;
  padding-left: @paddingWidth;
  background-color: @white;
}

// Border Radius
.border-radius(@radius) {
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  border-radius: @radius;
}

.border-radius(@topLeft, @topRight, @bottomLeft, @bottomRight) {
  -webkit-border-top-left-radius: @topLeft;
  -moz-border-radius-topleft: @topLeft;
  border-top-left-radius: @topLeft;

  -webkit-border-top-right-radius: @topRight;
  -moz-border-radius-topright: @topRight;
  border-top-right-radius: @topRight;

  -webkit-border-bottom-left-radius: @bottomLeft;
  -moz-border-radius-bottomleft: @bottomLeft;
  border-bottom-left-radius: @bottomLeft;

  -webkit-border-bottom-right-radius: @bottomRight;
  -moz-border-radius-bottomright: @bottomRight;
  border-bottom-right-radius: @bottomRight;
}

// Drop shadows
.box-shadow(@shadow) {
  -webkit-box-shadow: @shadow;
  -moz-box-shadow: @shadow;
  box-shadow: @shadow;
}

#gradient {
  .vertical(@startColor: @grey-stardust, @endColor: @grey-metal) {
    background-color: mix(@startColor, @endColor, 60%);
    background-image: -moz-linear-gradient(top, @startColor, @endColor); // FF 3.6+
    background-image: -webkit-gradient(
      linear,
      0 0,
      0 100%,
      from(@startColor),
      to(@endColor)
    ); // Safari 4+, Chrome 2+
    background-image: -webkit-linear-gradient(top, @startColor, @endColor); // Safari 5.1+, Chrome 10+
    background-image: -o-linear-gradient(top, @startColor, @endColor); // Opera 11.10
    background-image: linear-gradient(to bottom, @startColor, @endColor); // Standard, IE10
    background-repeat: repeat-x;
    filter: e(
      %(
        "progid:DXImageTransform.Microsoft.gradient(startColorstr='%d', endColorstr='%d', GradientType=0)",
        argb(@startColor),
        argb(@endColor)
      )
    ); // IE9 and down
  }
}

.circular(@size) {
  width: @size;
  height: @size;
  .border-radius(@size / 2);
}

// Creates a down-pointing arrow in a pseudo-element attached to the scoped element
// Based on http://davidwalsh.name/css-triangles
.down-triangle(@color, @top, @center, @size) {
  position: relative;

  &:before {
    content: " ";
    height: 0;
    width: 0;
    border: @size solid transparent;

    border-top-color: @color;

    position: absolute;
    top: @top;
    left: @center - @size;
  }
}

.flat-dropdown-menu {
  .border-radius(0);
  min-width: 150px;
}

// Removes default styles from select elements so they can be styled.  See
// https://bugzilla.mozilla.org/show_bug.cgi?id=649849 for an explanation of the text-indent/text-overflow
.no-appearance() {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 0.01px;
  text-overflow: "";
  appearance: none;

  &::-ms-expand {
    display: none;
  }
}

.standard-select() {
  height: 32px;
  font-family: @font-sailec-demi;
  .no-appearance();
  .border-radius(@standard-border-radius);
  color: @dark-text;
  padding-top: 3px;
  border: @standard-section-border;
  padding-left: 5px;
  padding-right: 16px;
  cursor: pointer;
  background: white url("~@images/dashboard/arrow.png") no-repeat calc(~"100% - 3px");
  line-height: normal;
  font-size: 14px;
}

.table-item-title() {
  background-color: @grey-ghost;
  padding: 8px 0;

  span {
    font-size: 14px;
    margin-left: 15px;
    font-weight: bold;
    color: @dark-text;
  }
}

.flip-horizontal() {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

.btn-font() {
  -webkit-font-smoothing: antialiased;
  font: 13px @font-sailec-demi;
  line-height: 15px;
}

.close-button() {
  cursor: pointer;
  font-size: 24px;
  line-height: 24px;
}

.warning-text() {
  color: @warning-light-red;
  font-weight: bold;
  margin-top: 0;
}

.link-styling() {
  color: @clickable-main;

  &:hover {
    text-decoration: underline;
  }
}

.app-selector-container(@total-width, @columns) {
  overflow-x: hidden;
  width: @total-width;
  margin-top: 15px;

  .app-selector {
    @image-size: 30px;
    @selector-width: @total-width / @columns - 8px;

    display: inline-block;
    width: @selector-width;

    input {
      vertical-align: top;
      margin-top: 10px;
    }

    label {
      display: inline-block;
      height: @image-size;
      line-height: normal;
      margin-top: 0;
      width: @selector-width - @image-size;

      span {
        font-weight: bold;
        display: inline-block;
        margin-left: 8px;
        white-space: nowrap;
        .ellipsis-overflow();
        max-width: @selector-width - @image-size - 42px;
      }

      img {
        height: @image-size;
        width: @image-size;
        margin-left: 3px;
        .border-radius(4px);
      }

      .app-selector-platform {
        font-weight: normal;
      }
    }
  }
}

.disabled-link() {
  color: @grey-french;
  cursor: default;

  i {
    color: @grey-french;
  }
}

.disjunction-set-connector(@background-color) {
  width: 5px;
  height: 18px;
  background-color: @background-color;
  position: relative;
  left: 17px;

  &.operator {
    text-align: center;
    font-family: @font-sailec-demi;
    height: 19px;
    width: 40px;
    padding: 3px 0;
    background-color: @grey-stardust;
    color: white;
    position: relative;
    left: 0;
    .border-radius(@standard-border-radius);
    &.and-text {
      text-transform: uppercase;
    }
  }
}

.ad-hoc-group(@select-width, @select-width-compact) {
  background-color: transparent;
  .border-radius(@standard-border-radius);
  padding: 11px;

  .title {
    font-family: @font-sailec-demi;
    font-size: 15px;
    vertical-align: middle;
  }

  select,
  .select-holder {
    width: @select-width;
    margin-left: 10px;
    margin-right: 10px;

    &.compact {
      width: @select-width-compact;
    }

    &.select-holder {
      display: inline-block;
    }
  }

  label {
    margin: 0 10px;
    display: inline-block;
    line-height: 28px;
    vertical-align: middle;
  }

  .delete-item {
    .delete-icon();
  }

  .parsley-errors-container {
    display: inline-block;
  }
}

.delete-icon() {
  cursor: pointer;
  margin-left: 15px;
  margin-top: 8px;
}

.handle-and-plus() {
  width: 35px;
  border-right: @standard-section-border;
  border-radius: @standard-border-radius 0 0 @standard-border-radius;
  background-color: @grey-ghost;
}

// The absolute positioning on this and its child img element are really hacky. But it's the only semi-
// reasonable (non-js) way I could find to get this to resize and vertically align appropriately as
// additional OR filters are added to a filter set.
.handle(@cursor) {
  position: absolute;
  cursor: @cursor;
  top: 0;
  bottom: 0;

  img {
    position: absolute;
    height: 15px;
    width: 3px;
    top: 50%;
    margin-top: -8px;
    margin-left: 16px;
  }
}

.plus-handle() {
  float: left;
  height: 59px;

  img {
    width: 9px;
    height: 9px;
    margin: 23px 0 0 13px;
  }
}

.modal-button(@width-percent) {
  width: @width-percent;
  padding-left: 0;
  padding-right: 0;
}

.center-vertically() {
  position: relative;
  top: 50%;
  .transform(translateY(-50%));
}

.center-both-ways() {
  position: absolute;
  top: 50%;
  left: 50%;
  .transform(translateX(-50%) translateY(-50%));
}

.center-text-both-ways-in-container() {
  .flex-display();
  .justify-content(center);
  .flex-direction(column);
  text-align: center;
}

.time-window-controls() {
  font-family: @font-sailec-medium;

  hr {
    border-top: @standard-section-border;
  }

  input[type="text"] {
    width: auto;
  }

  input[type="checkbox"] {
    margin-right: 10px;
  }

  .time-window-controls {
    label {
      display: inline-block;
      width: 150px;

      &.start-time {
        margin-left: 19px;
        width: 149px;
      }
    }

    .time-block:not(:first-of-type) {
      margin-top: 10px;
    }
  }

  .small-inline-warning {
    margin-top: 10px;
    margin-bottom: 0;
  }
}

.placeholder-color(@color) {
  &::-webkit-input-placeholder {
    color: @color;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: @color;
  }

  &:-ms-input-placeholder {
    color: @color;
  }
}

.generic-pill-close() {
  .transition(opacity 0.2s);
  margin-left: 7px;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}

.default-link-styles() {
  cursor: pointer;
  .link-styling();

  .fa,
  .fas,
  .fab,
  .far,
  &.inline-icon {
    color: @navy-bay;
    text-decoration: none;

    &:hover,
    &:active {
      text-decoration: none;
    }
  }

  &:hover {
    .fa,
    .fas,
    .far,
    .fab,
    &.inline-icon {
      color: @navy-cove;
    }
  }

  &.inactive {
    text-decoration: none;
    cursor: default;
    color: @grey-stardust;
  }

  &.disabled {
    .disabled-link();

    &:not(.btn) {
      i.fa,
      i.fas,
      i.far,
      i.fab {
        color: @grey-french;
      }
    }

    &:hover {
      text-decoration: none;
    }
  }

  &.green-link {
    color: @green-75-shamrock;

    .fa,
    .fas,
    .far,
    .fab,
    &.inline-icon {
      color: @green-75-shamrock;
    }
  }

  &.red-link {
    color: @warning-red;

    .fa,
    .far,
    .fas,
    .fab,
    &.inline-icon {
      color: @warning-red;
    }
  }
}

// https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
.aggressively-wrap() {
  // These are technically the same, but use both
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  // This is the dangerous one in WebKit, as it breaks things wherever
  word-break: break-all;
  // Instead use this non-standard one:
  word-break: break-word;

  // Adds a hyphen where the word breaks, if supported (No Blink)
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.text-outline(@color) {
  text-shadow:
    1px 1px 0 @color,
    -1px -1px 0 @color,
    1px -1px 0 @color,
    -1px 1px 0 @color;
}

.fixed-pill-header() {
  left: @side-col-width;
  width: calc(~"100%" - @side-col-width);
  position: fixed;
}
