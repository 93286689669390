/**
 * @owner @Appboy/clip
 */

@import "@braze/bootstrap-less/src/variables";
@import "helpers/colors";
@import "helpers/variables";
@import "helpers/mixins";
@import "dashboard/flex-mixins";
@import "parsley";
@import "@braze/bootstrap-less/src/mixins";
@import "@braze/beacon-fonts/Aribau.css";
@import "fontawesome/fontawesome";
@import "fontawesome/solid";
@import "fontawesome/regular";
@import "fontawesome/brands";
@import "fontawesome/v4-shims";

@fa-font-path: "~@vendor/fonts/font-awesome";

@input-top-padding: 10px;

@form-width: 425px;
@form-padding: 50px;

@gray-border: @grey-stardust;
@black-border: @grey-metal;
@link-color: @navy-bay;
@link-hover-color: @navy-cove;

@line-height: 105px;
@line-offset: 330px;
@line-width: 352px;

@free-trial-login-width: 850px;

html {
  background: none;
  overflow-x: hidden;
}

.btn-primary {
  background-color: @purple700;
  border-radius: 8px;
  height: 40px;
  cursor: pointer;
  font-family: @font-aribau;
  font-size: 15px;
  letter-spacing: 0.05em;
  padding: 0px 1.5rem;
  border: none;
  color: @white;

  &:hover:not(:disabled) {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.08) 100%), @purple700;
  }

  &:active:not(:disabled) {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.16) 100%), @purple700;
  }

  &:focus-visible:not(:disabled),
  &:active:not(:disabled) {
    outline: 2px solid @purple500;
    outline-offset: 2px;
    position: relative;
  }
}

.btn-quaternary {
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  border-radius: 8px;
  font-size: 14px;
  font-family: Aribau, Helvetica, Arial, sans-serif;
  font-weight: 700;
  position: relative;
  border: none;
  background: none;
  color: @purple700;
  padding: 2px;
  height: unset;

  &:hover:not(:disabled):not(.parsley-error) {
    text-decoration: underline;
  }

  &:active:not(:disabled):not(.parsley-error) {
    text-decoration: underline;
    color: #290a64;
  }

  &:focus:not(:disabled):not(.parsley-error) {
    text-decoration: underline;
  }

  &:focus-visible:not(:disabled),
  &:active:not(:disabled) {
    outline: 2px solid @purple500;
    outline-offset: 2px;
    position: relative;
  }
}

.btn-secondary {
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  font-size: 14px;
  font-family: Aribau, Helvetica, Arial, sans-serif;
  font-weight: 700;
  position: relative;
  padding: 0px 1.5rem;
  height: 40px;
  border-color: @purple500;
  color: @purple700;
  background: @white;

  &:hover:not(:disabled) {
    background: linear-gradient(0deg, rgba(147, 89, 255, 0.04) 0%, rgba(147, 89, 255, 0.04) 100%), @white;
  }

  &:active:not(:disabled) {
    background: linear-gradient(0deg, rgba(147, 89, 255, 0.08) 0%, rgba(147, 89, 255, 0.08) 100%), @white;
  }

  &:focus-visible:not(:disabled),
  &:active:not(:disabled) {
    outline: 2px solid @purple500;
    outline-offset: 2px;
    position: relative;
  }
}

.auth-right-half,
.two-factor-auth-form {
  input[type="email"],
  input[type="password"],
  input[type="text"],
  input[type="tel"] {
    border: 1px solid @gray500;
    border-radius: 8px;
    color: @gray900;
    font-family: Aribau, Helvetica, Arial, sans-serif;
    font-size: 14px;
    height: 40px;
    padding: 2px 12px 4px;
    display: block;
    width: 100%;
    box-sizing: border-box;

    &:hover:not(.parsley-error),
    &:focus:not(.parsley-error) {
      outline: none;
    }

    &:hover:not(:disabled):not(.parsley-error) {
      border-color: #313137;
    }

    &:focus:not(:disabled):not(.parsley-error),
    &:active:not(:disabled):not(.parsley-error) {
      padding: 0px 11px 2px;
      border-width: 2px;
      border-color: @purple500;
      background: @gray000;
      caret-color: @purple500;
    }

    &.parsley-error {
      border: 1px solid @beacon-danger-500;
      font-family: Aribau, Helvetica, Arial, sans-serif;
      font-size: 14px;
      color: @gray900;
    }
  }

  input[type="tel"] {
    padding-left: 52px;
  }

  input[type="radio"] {
    display: grid;
    place-content: center;
    appearance: none;
    background-color: #fff;
    cursor: pointer;
    margin: 0;
    position: relative;
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border-radius: 50%;
    border-color: @gray500;
    border-style: solid;
    border-width: 1px;
    outline-offset: 4px;
    outline-color: transparent;
    transition: 0.15s, border-width, top, left;
    transform: translateY(-0.075em);
  }

  input[type="radio"]:hover {
    box-shadow: rgb(233, 235, 237) 0px 0px 0px 5px;
  }

  input[type="radio"]::before {
    content: "";
    background-color: @purple700;
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    opacity: 0;
    transition: 120ms transform ease-in-out;
  }

  input[type="radio"]:checked::before {
    opacity: 1;
  }

  input[type="radio"]:focus-visible:checked:not(:disabled):not([data-invalid]) {
    outline-color: @purple500;
    border-color: @purple700;
    box-shadow: 0 0 0 5px rgba(147, 89, 255, 0.08);
  }

  input[type="radio"]:focus-visible:not(:checked):not(:disabled):not([data-invalid]) {
    outline-color: @purple500;
    border-color: @gray800;
    box-shadow: 0 0 0 5px rgba(49, 49, 55, 0.08);
  }
}

.auth-right-half {
  .login-form {
    height: auto;
    margin: 0 auto;
    top: 40%;
    transform: translateY(-50%);
    position: relative;

    .btn-secondary,
    .btn-quaternary {
      width: 100%;
    }
  }

  main {
    border-width: 0px;
    box-shadow: none;
    box-sizing: inherit;
  }

  .login-form,
  main {
    height: auto;
    margin: 0 auto;
    top: 40%;
    transform: translateY(-50%);
    position: relative;
  }

  .two-factor-auth-form {
    transform: none;
    top: 40%;
    position: relative;
  }

  input.btn.submit {
    background-color: @purple700;
    border-radius: 8px;
    height: 40px;
    cursor: pointer;
    font-family: Aribau, Helvetica, Arial, sans-serif;
    font-size: 15px;
    letter-spacing: 0.05em;
    padding: 0px 1.5rem;
    border: none;
    color: @white;

    &:hover:not(:disabled) {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.08) 100%), @purple700;
    }

    &:active:not(:disabled) {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.16) 0%, rgba(255, 255, 255, 0.16) 100%), @purple700;
    }

    &:focus-visible:not(:disabled),
    &:active:not(:disabled) {
      outline: 2px solid @purple500;
      outline-offset: 2px;
      position: relative;
    }
  }

  .btn-major {
    width: 100%;
  }

  input[type="checkbox"] {
    appearance: none;
    margin: 0px;
    font-style: inherit;
    font-variant: inherit;
    font-weight: inherit;
    font-stretch: inherit;
    font-size: inherit;
    font-family: inherit;
    font-optical-sizing: inherit;
    font-size-adjust: inherit;
    font-kerning: inherit;
    font-feature-settings: inherit;
    font-variation-settings: inherit;
    width: 18px;
    height: 18px;
    border-radius: 5px;
    border: 1px solid @gray500;
    transition:
      background-color 0.3s,
      border-color 0.3s;
    line-height: normal;
    color: white;
    display: grid;
    place-content: center;
    cursor: pointer;
    background: white;
    box-sizing: border-box;
    padding: initial;
    margin-right: 8px;
  }

  input[type="checkbox"]:hover,
  input[type="checkbox"]:focus {
    outline: none;
  }

  input[type="checkbox"]:not(:disabled):hover {
    border-color: @gray800;
    box-shadow: 0 0 0 5px rgba(49, 49, 55, 0.08);
  }

  input[type="checkbox"]:not(:disabled):focus-visible {
    box-shadow: 0 0 0 3px rgba(147, 89, 255, 0.08);
    outline: 2px solid @purple500;
    outline-offset: 3px;
    border-color: @gray800;
  }

  input[type="checkbox"]:checked:before {
    content: "";
    width: 16px;
    height: 16px;
    clip-path: polygon(76.167% 23.833%, 84.5% 32.167%, 40.5% 79%, 16.5% 54%, 24.833% 45.833%, 40.5% 62.667%);
    transform: scale(0);
    transform-origin: center center;
    background-color: rgb(255, 255, 255);
    transform: scale(1);
  }

  input[type="checkbox"]:checked {
    border: 1px solid @purple700;
    background: @purple700;
  }

  .login-form .container .sign-in-banner,
  .login-form .container h1 {
    margin: 0 0 25px 0;
    font-family: @font-aribau;
    font-size: 24px;
    line-height: 30px;
    color: @gray800;
    text-align: center;
  }

  .login-form {
    .password-form {
      display: inline;
    }

    .toggle-hide-show {
      position: absolute;
      top: 12px;
      right: 12px;
      color: @gray600;
    }

    .email-text {
      display: block;
      margin: 0 0 10px 0;
      font-family: @font-aribau;
      font-size: 13px;
      color: @gray800;
    }

    #developer-email::placeholder,
    #developer-password::placeholder {
      font-style: italic;
      font-family: @font-aribau;
      color: @gray600;
    }

    .footer {
      margin-top: 20px;
    }

    .text-link {
      display: inline-flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      cursor: pointer;
      border-radius: 8px;
      font-size: 14px;
      font-family: Aribau, Helvetica, Arial, sans-serif;
      font-weight: 700;
      position: relative;
      width: 100%;
      border: none;
      background: none;
      color: @purple700;
      padding: 2px;
      height: unset;

      &:hover:not(:disabled) {
        text-decoration: underline;
      }

      &:active:not(:disabled) {
        text-decoration: underline;
        color: #290a64;
      }

      &:focus:not(:disabled) {
        text-decoration: underline;
      }

      &:focus-visible:not(:disabled),
      &:active:not(:disabled) {
        outline: 2px solid @purple500;
        outline-offset: 2px;
        position: relative;
      }
    }

    .forgot-password {
      margin-top: 20px;
    }

    .sso-separator {
      margin-top: 25px;
      overflow: hidden;
      text-align: center;
      font-family: @font-aribau;
      font-weight: 700;
      font-size: 13px;
      line-height: 18px;
      color: @gray800;
      cursor: pointer;
      vertical-align: baseline;
    }

    .sso-separator::before,
    .sso-separator::after {
      background-color: @gray200;
      content: "";
      display: inline-block;
      height: 1px;
      position: relative;
      vertical-align: middle;
      width: 50%;
    }

    .sso-separator::before {
      right: 0.5em;
      margin-left: -50%;
    }

    .sso-separator::after {
      left: 0.5em;
      margin-right: -50%;
    }

    .btn.third-party-auth-button {
      display: inline-flex;
      -webkit-box-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      align-items: center;
      cursor: pointer;
      border-width: 1px;
      border-style: solid;
      border-radius: 8px;
      font-size: 14px;
      font-family: Aribau, Helvetica, Arial, sans-serif;
      font-weight: 700;
      position: relative;
      width: 100%;
      padding: 0px 1.5rem;
      height: 40px;
      border-color: @purple500;
      color: @purple700;
      background: @white;

      &:hover:not(:disabled) {
        background: linear-gradient(0deg, rgba(147, 89, 255, 0.04) 0%, rgba(147, 89, 255, 0.04) 100%), @white;
      }

      &:active:not(:disabled) {
        background: linear-gradient(0deg, rgba(147, 89, 255, 0.08) 0%, rgba(147, 89, 255, 0.08) 100%), @white;
      }

      &:focus-visible:not(:disabled),
      &:active:not(:disabled) {
        outline: 2px solid @purple500;
        outline-offset: 2px;
        position: relative;
      }
    }

    .notice {
      border-width: 1px;
      border-color: @green400;
      border-left-width: 7px;
      border-radius: 5px;
      border-style: solid;
      margin-bottom: 15px;
      font-family: @font-aribau;
      font-size: 12px;
      height: 58px;
      margin-right: 5px;

      .notice-text {
        place-content: center;
        display: block;
        height: 100%;
      }

      .notice-check,
      .check,
      .circle {
        float: left;
        display: grid;
        line-height: normal;
        height: 49px;
        width: 35px;
        margin-right: 5px;
        place-content: center;
        text-align: center;
      }

      .check {
        margin-left: 10px;
        color: @green400;
        font-size: 13px;
        font-size: 13px;
      }

      .circle {
        margin-left: 10px;
        color: @green100;
        font-size: 24px;
      }
    }
  }

  footer {
    text-align: left;
    margin-top: 30px;
  }

  #another-verification-method,
  #back-to-enter-number {
    margin-bottom: 20px;
  }
}

.password-form {
  display: flex;
  width: @form-width;
  height: auto;
  margin: 0 auto;
  top: 40%;
  transform: translateY(-50%);
  position: relative;

  .unlock-link {
    margin-top: 5px;
    display: block;
  }

  .selected-email {
    font-size: 14px;
  }

  .email-back {
    display: inline-block;
    vertical-align: middle;
  }

  .back-button {
    height: 27px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }

  .clusters-holder {
    margin-top: 40px;
    font-size: 14px;
  }

  .small-link {
    font-size: 12px;
    color: @link-color;

    &:hover {
      color: @link-hover-color;
    }
  }

  .clusters-list {
    font-size: 18px;
    list-style: none;
    padding: 0;
    margin-top: 40px;

    li {
      margin-bottom: 15px;

      a {
        color: @link-color;

        &:hover {
          color: @link-hover-color;
        }
      }
    }
  }

  .forgot-password {
    margin-top: 10px;
  }

  .third-party-auth-buttons {
    .flex-display();
    a {
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
    .btn {
      border: 2px solid @link-color;
      box-sizing: border-box;
      line-height: 41px;
    }
  }

  @media only screen and (max-width: @form-width) {
    width: 100%;
  }

  .container {
    &.short {
      padding-bottom: 25px;
    }

    .contact {
      clear: both;
      font-size: 11px;
      text-align: center;
      font-family: @font-aribau;
    }

    .error-msg {
      font-size: 12px;
      font-weight: bold;
      color: @warning-red;
      margin-top: 5px;
    }

    .remember-device {
      top: -10px;
      left: 80px;
      position: relative;
      font-size: 12px;
      cursor: pointer;
    }

    .verify {
      margin-top: 30px;
    }

    .request-sms {
      font-family: @font-aribau;
      color: @dark-text;
      font-size: 13px;
      width: 650px;
      position: relative;
      bottom: 157px;
      right: 218px;

      input {
        border: none;
        background: none;
        font-size: 13px;
        &:focus {
          outline: 0;
        }
        cursor: pointer;
      }

      &.installation {
        bottom: 106px;
        right: 209px;

        input {
          position: relative;
          left: 162px;
          bottom: 32.5px;
        }
      }
    }

    .footer.installation {
      margin-top: 55px;
    }

    @media only screen and (max-width: @form-width) {
      border: none;
      padding: 25px 20px;

      .oauth-container {
        margin: 10px 0;
      }

      .remember-device {
        top: 0px;
        left: 0px;
      }

      .request-sms {
        bottom: 150px;

        &.installation {
          bottom: 115px;

          input {
            left: -7px;
            bottom: 13px;
          }
        }
      }

      .footer.installation {
        margin-top: 45px;
      }
    }

    .header-bar {
      display: block;
      background: url("https://appboy-images.com/dashboard-assets/dashboard/braze-logo-black.svg") no-repeat;
      background-size: 150px;
      height: 69px;
      width: 150px;
      margin: auto;
      margin-bottom: @form-padding;
    }
  }

  h1 {
    margin: 0 0 20px 0;
    font-family: @font-aribau;
    font-size: 24px;
    line-height: 30px;
    color: @gray800;
    text-align: center;
  }

  .user-email {
    margin: 0 0 20px 0;
    font-family: @font-aribau;
    font-size: 13px;
    color: @gray800;
    display: block;
  }

  .password-text {
    display: block;
    margin: 0 0 10px 0;
    font-family: @font-aribau;
    font-size: 13px;
    color: @gray800;
  }

  .input {
    #developer-password,
    #developer-password-confirmation {
      border: 1px solid @gray300;
      border-radius: 2px;
      color: @gray600;
      font-family: @font-aribau;
      font-size: 14px;
      font-style: italic;
      padding-left: 5px;

      &:hover {
        border: 1px solid @gray800;
      }

      &:focus {
        border: 1px solid @lightBlue500;
      }

      &.parsley-error {
        border: 2px solid @beacon-danger-500;
        font-family: @font-aribau;
        font-size: 14px;
        color: @gray900;
      }
    }
  }

  .parsley-errors-list li:before {
    content: "\f071";
  }

  .free-trial-btn:disabled {
    background-color: @gray300;
  }

  .free-trial-btn {
    background-color: @beacon-primary-500;
    width: 100%;
    height: 45px;
    cursor: pointer;
    font-family: @font-aribau;
    font-size: 15px;
    letter-spacing: 0.05em;
    border: none;
    color: @white;
  }

  .password-tooltip-container {
    position: absolute;
    background-color: white;
    visibility: hidden;
    z-index: 1;
    top: -200%;
    left: 105%;
    width: 230px;
    border-color: @gray300;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 5px;
  }

  .password-tooltip-container .righttooltiptext {
    visibility: hidden;
    width: 230px;
    background-color: transparent;
    color: @black;
    border-color: @black;
    padding: 4px 4px;
    margin: 0;
    font-size: 14px;
    font-family: @font-aribau;
  }

  #right-tooltip-title {
    font-family: @font-aribau;
  }

  #developer-password:hover .righttooltiptext {
    visibility: visible;
  }

  .password-tooltip-container::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -4px;
    border-width: 4px;
    border-style: solid;
    border-color: transparent white transparent transparent;
  }

  i.toggle-hide-show#password-toggle,
  i.toggle-hide-show#confirm-password-toggle {
    position: absolute;
    top: 30%;
    right: 1%;
    color: @gray600;
  }

  .password-tooltip-container::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent @gray300 transparent transparent;
  }

  #password_rules_tooltip {
    position: relative;
    padding-bottom: 15px;
    margin-top: -5px;
    display: inline-block;
    font-size: 13px;
  }

  #password_rules_tooltip .tooltiptext {
    width: 300px;
    background-color: transparent;
    color: @black;
    border-radius: 6px;
    z-index: 1;
    top: -5px;
    margin: 5px;
  }

  #password-confirmation-control-group {
    margin-bottom: 15px;
  }

  .tooltiptext {
    display: block;
  }

  .password_check_icon {
    color: @gray300;
  }

  .password_confirmation_check_icon {
    color: @gray300;
  }
}

.auth-left-half {
  display: inline-block;
  top: 0;
  left: 0;
  position: absolute;
  float: left;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 40vw;
  margin: 0;
  background-size: cover;
}

#auth-left-half-layer-1 {
  background-position: center;
  background-repeat: no-repeat;
  background: url("http://appboy-images.com/dashboard-assets/dashboard/registration-bg-gradient.jpg");
  z-index: 1;
  height: 100vh;
  width: 40vw;
  background-size: cover;
}

#auth-left-half-layer-2 {
  background-position: center;
  background-repeat: no-repeat;
  background: url("http://appboy-images.com/dashboard-assets/dashboard/registration-bg-loop.jpg");
  z-index: 2;
  width: 40vw;
  height: 100vh;
  max-height: 1350px;
  background-size: cover;
}

#auth-left-half-layer-3 {
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background: url("http://appboy-images.com/dashboard-assets/dashboard/registration-bg-image.jpeg");
  max-width: 900px;
  max-height: 1350px;
  width: 40vw;
  height: 60vw;
  z-index: 3;
  background-size: cover;
}

@media only screen and (min-width: 2248px) {
  #auth-left-half-layer-3 {
    margin-left: calc((40vw - 900px) / 2);
    margin-right: calc((40vw - 900px) / 2);
  }
}

.auth-right-half {
  display: inline-block;
  float: left;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 50px);
  width: 60vw;
  position: absolute;
  right: 0;
  margin-top: 50px;
}

.auth-page {
  display: flex;
  justify-content: center;
}

.privacy-policy-tos-confirmation {
  font-size: 13px;
  font-family: @font-aribau;
}

.auth-left-half-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-top-right-radius: 45px;
  border-bottom-right-radius: 45px;
}

#auth-left-half-text {
  color: var(--White, var(--Color-Gray-000, #fff));
  text-align: center;
  font-feature-settings: "ss02" on;
  font-family: "Aribau Grotesk";
  font-size: 97.274px;
  font-style: normal;
  font-weight: 800;
  line-height: 85%; /* 82.683px */
  letter-spacing: -3.891px;
  filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.25));
  display: flex;
  width: 601px;
  height: auto;
  margin: 0 auto;
  top: 85%;
  transform: translateY(-80%);
  position: relative;
  z-index: 4;
}

#auth-left-half-trademark {
  color: var(--White, var(--Color-Gray-000, #fff));
  text-align: center;
  font-feature-settings: "ss02" on;
  font-family: "Aribau Grotesk";
  font-size: 16px;
  font-style: normal;
  font-weight: 550;
  line-height: 23.48px;
  letter-spacing: 0.5px;
  filter: drop-shadow(0px 4px 14px rgba(0, 0, 0, 0.25));
  position: absolute;
  top: 100px;
  right: 75px;
}

.privacy-policy-tos-link {
  font-family: @font-aribau;
  font-weight: 700;
  font-size: 13px;
  text-decoration: underline;
  color: rgb(24, 113, 216);
  cursor: pointer;
  vertical-align: baseline;
}

#braze-logo-left-half {
  z-index: 4;
  position: absolute;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
  width: 103px;
  height: 48px;
  margin-top: 41px;
  margin-left: 58px;
}

#braze-logo-right-half {
  filter: invert(13%) sepia(76%) saturate(6254%) hue-rotate(271deg) brightness(90%) contrast(89%);
  visibility: hidden;
  width: 103px;
  height: 48px;
  width: 100%;
  padding-bottom: 20px;
}

#pp_tos_acknowledgment_text_col {
  padding-left: 5px;
}

@media only screen and (max-width: 992px) {
  .auth-left-half {
    display: none !important;
  }
}

@media only screen and (max-width: 992px) {
  .auth-right-half {
    width: 100vw;
    display: block;
  }

  #braze-logo-right-half {
    visibility: visible;
  }
}

@media only screen and (max-width: 1800px) {
  #auth-left-half-text {
    font-size: 80px;
  }

  #auth-left-half-trademark {
    top: 82px;
    right: 112px;
  }
}

@media only screen and (max-width: 1500px) {
  #auth-left-half-text {
    font-size: 75px;
    top: 82%;
    width: 500px;
  }

  #auth-left-half-trademark {
    font-size: 15px;
    top: 75px;
    right: 76px;
  }
}

@media only screen and (max-width: 1300px) {
  #auth-left-half-text {
    font-size: 70px;
    top: 79%;
    width: 450px;
  }

  #auth-left-half-trademark {
    font-size: 14px;
    top: 69px;
    right: 64px;
  }

  #auth-left-half-layer-3 {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 1150px) {
  #auth-left-half-text {
    font-size: 65px;
    top: 76%;
    width: 400px;
  }

  #auth-left-half-trademark {
    font-size: 13px;
    top: 63px;
    right: 50px;
  }
}
