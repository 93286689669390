/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */

.@{fa-css-prefix}-500px:before {
  content: @fa-var-500px;
}
.@{fa-css-prefix}-accessible-icon:before {
  content: @fa-var-accessible-icon;
}
.@{fa-css-prefix}-accusoft:before {
  content: @fa-var-accusoft;
}
.@{fa-css-prefix}-acquisitions-incorporated:before {
  content: @fa-var-acquisitions-incorporated;
}
.@{fa-css-prefix}-ad:before {
  content: @fa-var-ad;
}
.@{fa-css-prefix}-address-book:before {
  content: @fa-var-address-book;
}
.@{fa-css-prefix}-address-card:before {
  content: @fa-var-address-card;
}
.@{fa-css-prefix}-adjust:before {
  content: @fa-var-adjust;
}
.@{fa-css-prefix}-adn:before {
  content: @fa-var-adn;
}
.@{fa-css-prefix}-adversal:before {
  content: @fa-var-adversal;
}
.@{fa-css-prefix}-affiliatetheme:before {
  content: @fa-var-affiliatetheme;
}
.@{fa-css-prefix}-air-freshener:before {
  content: @fa-var-air-freshener;
}
.@{fa-css-prefix}-airbnb:before {
  content: @fa-var-airbnb;
}
.@{fa-css-prefix}-algolia:before {
  content: @fa-var-algolia;
}
.@{fa-css-prefix}-align-center:before {
  content: @fa-var-align-center;
}
.@{fa-css-prefix}-align-justify:before {
  content: @fa-var-align-justify;
}
.@{fa-css-prefix}-align-left:before {
  content: @fa-var-align-left;
}
.@{fa-css-prefix}-align-right:before {
  content: @fa-var-align-right;
}
.@{fa-css-prefix}-alipay:before {
  content: @fa-var-alipay;
}
.@{fa-css-prefix}-allergies:before {
  content: @fa-var-allergies;
}
.@{fa-css-prefix}-amazon:before {
  content: @fa-var-amazon;
}
.@{fa-css-prefix}-amazon-pay:before {
  content: @fa-var-amazon-pay;
}
.@{fa-css-prefix}-ambulance:before {
  content: @fa-var-ambulance;
}
.@{fa-css-prefix}-american-sign-language-interpreting:before {
  content: @fa-var-american-sign-language-interpreting;
}
.@{fa-css-prefix}-amilia:before {
  content: @fa-var-amilia;
}
.@{fa-css-prefix}-anchor:before {
  content: @fa-var-anchor;
}
.@{fa-css-prefix}-android:before {
  content: @fa-var-android;
}
.@{fa-css-prefix}-angellist:before {
  content: @fa-var-angellist;
}
.@{fa-css-prefix}-angle-double-down:before {
  content: @fa-var-angle-double-down;
}
.@{fa-css-prefix}-angle-double-left:before {
  content: @fa-var-angle-double-left;
}
.@{fa-css-prefix}-angle-double-right:before {
  content: @fa-var-angle-double-right;
}
.@{fa-css-prefix}-angle-double-up:before {
  content: @fa-var-angle-double-up;
}
.@{fa-css-prefix}-angle-down:before {
  content: @fa-var-angle-down;
}
.@{fa-css-prefix}-angle-left:before {
  content: @fa-var-angle-left;
}
.@{fa-css-prefix}-angle-right:before {
  content: @fa-var-angle-right;
}
.@{fa-css-prefix}-angle-up:before {
  content: @fa-var-angle-up;
}
.@{fa-css-prefix}-angry:before {
  content: @fa-var-angry;
}
.@{fa-css-prefix}-angrycreative:before {
  content: @fa-var-angrycreative;
}
.@{fa-css-prefix}-angular:before {
  content: @fa-var-angular;
}
.@{fa-css-prefix}-ankh:before {
  content: @fa-var-ankh;
}
.@{fa-css-prefix}-app-store:before {
  content: @fa-var-app-store;
}
.@{fa-css-prefix}-app-store-ios:before {
  content: @fa-var-app-store-ios;
}
.@{fa-css-prefix}-apper:before {
  content: @fa-var-apper;
}
.@{fa-css-prefix}-apple:before {
  content: @fa-var-apple;
}
.@{fa-css-prefix}-apple-alt:before {
  content: @fa-var-apple-alt;
}
.@{fa-css-prefix}-apple-pay:before {
  content: @fa-var-apple-pay;
}
.@{fa-css-prefix}-archive:before {
  content: @fa-var-archive;
}
.@{fa-css-prefix}-archway:before {
  content: @fa-var-archway;
}
.@{fa-css-prefix}-arrow-alt-circle-down:before {
  content: @fa-var-arrow-alt-circle-down;
}
.@{fa-css-prefix}-arrow-alt-circle-left:before {
  content: @fa-var-arrow-alt-circle-left;
}
.@{fa-css-prefix}-arrow-alt-circle-right:before {
  content: @fa-var-arrow-alt-circle-right;
}
.@{fa-css-prefix}-arrow-alt-circle-up:before {
  content: @fa-var-arrow-alt-circle-up;
}
.@{fa-css-prefix}-arrow-circle-down:before {
  content: @fa-var-arrow-circle-down;
}
.@{fa-css-prefix}-arrow-circle-left:before {
  content: @fa-var-arrow-circle-left;
}
.@{fa-css-prefix}-arrow-circle-right:before {
  content: @fa-var-arrow-circle-right;
}
.@{fa-css-prefix}-arrow-circle-up:before {
  content: @fa-var-arrow-circle-up;
}
.@{fa-css-prefix}-arrow-down:before {
  content: @fa-var-arrow-down;
}
.@{fa-css-prefix}-arrow-left:before {
  content: @fa-var-arrow-left;
}
.@{fa-css-prefix}-arrow-right:before {
  content: @fa-var-arrow-right;
}
.@{fa-css-prefix}-arrow-up:before {
  content: @fa-var-arrow-up;
}
.@{fa-css-prefix}-arrows-alt:before {
  content: @fa-var-arrows-alt;
}
.@{fa-css-prefix}-arrows-alt-h:before {
  content: @fa-var-arrows-alt-h;
}
.@{fa-css-prefix}-arrows-alt-v:before {
  content: @fa-var-arrows-alt-v;
}
.@{fa-css-prefix}-artstation:before {
  content: @fa-var-artstation;
}
.@{fa-css-prefix}-assistive-listening-systems:before {
  content: @fa-var-assistive-listening-systems;
}
.@{fa-css-prefix}-asterisk:before {
  content: @fa-var-asterisk;
}
.@{fa-css-prefix}-asymmetrik:before {
  content: @fa-var-asymmetrik;
}
.@{fa-css-prefix}-at:before {
  content: @fa-var-at;
}
.@{fa-css-prefix}-atlas:before {
  content: @fa-var-atlas;
}
.@{fa-css-prefix}-atlassian:before {
  content: @fa-var-atlassian;
}
.@{fa-css-prefix}-atom:before {
  content: @fa-var-atom;
}
.@{fa-css-prefix}-audible:before {
  content: @fa-var-audible;
}
.@{fa-css-prefix}-audio-description:before {
  content: @fa-var-audio-description;
}
.@{fa-css-prefix}-autoprefixer:before {
  content: @fa-var-autoprefixer;
}
.@{fa-css-prefix}-avianex:before {
  content: @fa-var-avianex;
}
.@{fa-css-prefix}-aviato:before {
  content: @fa-var-aviato;
}
.@{fa-css-prefix}-award:before {
  content: @fa-var-award;
}
.@{fa-css-prefix}-aws:before {
  content: @fa-var-aws;
}
.@{fa-css-prefix}-baby:before {
  content: @fa-var-baby;
}
.@{fa-css-prefix}-baby-carriage:before {
  content: @fa-var-baby-carriage;
}
.@{fa-css-prefix}-backspace:before {
  content: @fa-var-backspace;
}
.@{fa-css-prefix}-backward:before {
  content: @fa-var-backward;
}
.@{fa-css-prefix}-bacon:before {
  content: @fa-var-bacon;
}
.@{fa-css-prefix}-bacteria:before {
  content: @fa-var-bacteria;
}
.@{fa-css-prefix}-bacterium:before {
  content: @fa-var-bacterium;
}
.@{fa-css-prefix}-bahai:before {
  content: @fa-var-bahai;
}
.@{fa-css-prefix}-balance-scale:before {
  content: @fa-var-balance-scale;
}
.@{fa-css-prefix}-balance-scale-left:before {
  content: @fa-var-balance-scale-left;
}
.@{fa-css-prefix}-balance-scale-right:before {
  content: @fa-var-balance-scale-right;
}
.@{fa-css-prefix}-ban:before {
  content: @fa-var-ban;
}
.@{fa-css-prefix}-band-aid:before {
  content: @fa-var-band-aid;
}
.@{fa-css-prefix}-bandcamp:before {
  content: @fa-var-bandcamp;
}
.@{fa-css-prefix}-barcode:before {
  content: @fa-var-barcode;
}
.@{fa-css-prefix}-bars:before {
  content: @fa-var-bars;
}
.@{fa-css-prefix}-baseball-ball:before {
  content: @fa-var-baseball-ball;
}
.@{fa-css-prefix}-basketball-ball:before {
  content: @fa-var-basketball-ball;
}
.@{fa-css-prefix}-bath:before {
  content: @fa-var-bath;
}
.@{fa-css-prefix}-battery-empty:before {
  content: @fa-var-battery-empty;
}
.@{fa-css-prefix}-battery-full:before {
  content: @fa-var-battery-full;
}
.@{fa-css-prefix}-battery-half:before {
  content: @fa-var-battery-half;
}
.@{fa-css-prefix}-battery-quarter:before {
  content: @fa-var-battery-quarter;
}
.@{fa-css-prefix}-battery-three-quarters:before {
  content: @fa-var-battery-three-quarters;
}
.@{fa-css-prefix}-battle-net:before {
  content: @fa-var-battle-net;
}
.@{fa-css-prefix}-bed:before {
  content: @fa-var-bed;
}
.@{fa-css-prefix}-beer:before {
  content: @fa-var-beer;
}
.@{fa-css-prefix}-behance:before {
  content: @fa-var-behance;
}
.@{fa-css-prefix}-behance-square:before {
  content: @fa-var-behance-square;
}
.@{fa-css-prefix}-bell:before {
  content: @fa-var-bell;
}
.@{fa-css-prefix}-bell-slash:before {
  content: @fa-var-bell-slash;
}
.@{fa-css-prefix}-bezier-curve:before {
  content: @fa-var-bezier-curve;
}
.@{fa-css-prefix}-bible:before {
  content: @fa-var-bible;
}
.@{fa-css-prefix}-bicycle:before {
  content: @fa-var-bicycle;
}
.@{fa-css-prefix}-biking:before {
  content: @fa-var-biking;
}
.@{fa-css-prefix}-bimobject:before {
  content: @fa-var-bimobject;
}
.@{fa-css-prefix}-binoculars:before {
  content: @fa-var-binoculars;
}
.@{fa-css-prefix}-biohazard:before {
  content: @fa-var-biohazard;
}
.@{fa-css-prefix}-birthday-cake:before {
  content: @fa-var-birthday-cake;
}
.@{fa-css-prefix}-bitbucket:before {
  content: @fa-var-bitbucket;
}
.@{fa-css-prefix}-bitcoin:before {
  content: @fa-var-bitcoin;
}
.@{fa-css-prefix}-bity:before {
  content: @fa-var-bity;
}
.@{fa-css-prefix}-black-tie:before {
  content: @fa-var-black-tie;
}
.@{fa-css-prefix}-blackberry:before {
  content: @fa-var-blackberry;
}
.@{fa-css-prefix}-blender:before {
  content: @fa-var-blender;
}
.@{fa-css-prefix}-blender-phone:before {
  content: @fa-var-blender-phone;
}
.@{fa-css-prefix}-blind:before {
  content: @fa-var-blind;
}
.@{fa-css-prefix}-blog:before {
  content: @fa-var-blog;
}
.@{fa-css-prefix}-blogger:before {
  content: @fa-var-blogger;
}
.@{fa-css-prefix}-blogger-b:before {
  content: @fa-var-blogger-b;
}
.@{fa-css-prefix}-bluetooth:before {
  content: @fa-var-bluetooth;
}
.@{fa-css-prefix}-bluetooth-b:before {
  content: @fa-var-bluetooth-b;
}
.@{fa-css-prefix}-bold:before {
  content: @fa-var-bold;
}
.@{fa-css-prefix}-bolt:before {
  content: @fa-var-bolt;
}
.@{fa-css-prefix}-bomb:before {
  content: @fa-var-bomb;
}
.@{fa-css-prefix}-bone:before {
  content: @fa-var-bone;
}
.@{fa-css-prefix}-bong:before {
  content: @fa-var-bong;
}
.@{fa-css-prefix}-book:before {
  content: @fa-var-book;
}
.@{fa-css-prefix}-book-dead:before {
  content: @fa-var-book-dead;
}
.@{fa-css-prefix}-book-medical:before {
  content: @fa-var-book-medical;
}
.@{fa-css-prefix}-book-open:before {
  content: @fa-var-book-open;
}
.@{fa-css-prefix}-book-reader:before {
  content: @fa-var-book-reader;
}
.@{fa-css-prefix}-bookmark:before {
  content: @fa-var-bookmark;
}
.@{fa-css-prefix}-bootstrap:before {
  content: @fa-var-bootstrap;
}
.@{fa-css-prefix}-border-all:before {
  content: @fa-var-border-all;
}
.@{fa-css-prefix}-border-none:before {
  content: @fa-var-border-none;
}
.@{fa-css-prefix}-border-style:before {
  content: @fa-var-border-style;
}
.@{fa-css-prefix}-bowling-ball:before {
  content: @fa-var-bowling-ball;
}
.@{fa-css-prefix}-box:before {
  content: @fa-var-box;
}
.@{fa-css-prefix}-box-open:before {
  content: @fa-var-box-open;
}
.@{fa-css-prefix}-box-tissue:before {
  content: @fa-var-box-tissue;
}
.@{fa-css-prefix}-boxes:before {
  content: @fa-var-boxes;
}
.@{fa-css-prefix}-braille:before {
  content: @fa-var-braille;
}
.@{fa-css-prefix}-brain:before {
  content: @fa-var-brain;
}
.@{fa-css-prefix}-bread-slice:before {
  content: @fa-var-bread-slice;
}
.@{fa-css-prefix}-briefcase:before {
  content: @fa-var-briefcase;
}
.@{fa-css-prefix}-briefcase-medical:before {
  content: @fa-var-briefcase-medical;
}
.@{fa-css-prefix}-broadcast-tower:before {
  content: @fa-var-broadcast-tower;
}
.@{fa-css-prefix}-broom:before {
  content: @fa-var-broom;
}
.@{fa-css-prefix}-brush:before {
  content: @fa-var-brush;
}
.@{fa-css-prefix}-btc:before {
  content: @fa-var-btc;
}
.@{fa-css-prefix}-buffer:before {
  content: @fa-var-buffer;
}
.@{fa-css-prefix}-bug:before {
  content: @fa-var-bug;
}
.@{fa-css-prefix}-building:before {
  content: @fa-var-building;
}
.@{fa-css-prefix}-bullhorn:before {
  content: @fa-var-bullhorn;
}
.@{fa-css-prefix}-bullseye:before {
  content: @fa-var-bullseye;
}
.@{fa-css-prefix}-burn:before {
  content: @fa-var-burn;
}
.@{fa-css-prefix}-buromobelexperte:before {
  content: @fa-var-buromobelexperte;
}
.@{fa-css-prefix}-bus:before {
  content: @fa-var-bus;
}
.@{fa-css-prefix}-bus-alt:before {
  content: @fa-var-bus-alt;
}
.@{fa-css-prefix}-business-time:before {
  content: @fa-var-business-time;
}
.@{fa-css-prefix}-buy-n-large:before {
  content: @fa-var-buy-n-large;
}
.@{fa-css-prefix}-buysellads:before {
  content: @fa-var-buysellads;
}
.@{fa-css-prefix}-calculator:before {
  content: @fa-var-calculator;
}
.@{fa-css-prefix}-calendar:before {
  content: @fa-var-calendar;
}
.@{fa-css-prefix}-calendar-alt:before {
  content: @fa-var-calendar-alt;
}
.@{fa-css-prefix}-calendar-check:before {
  content: @fa-var-calendar-check;
}
.@{fa-css-prefix}-calendar-day:before {
  content: @fa-var-calendar-day;
}
.@{fa-css-prefix}-calendar-minus:before {
  content: @fa-var-calendar-minus;
}
.@{fa-css-prefix}-calendar-plus:before {
  content: @fa-var-calendar-plus;
}
.@{fa-css-prefix}-calendar-times:before {
  content: @fa-var-calendar-times;
}
.@{fa-css-prefix}-calendar-week:before {
  content: @fa-var-calendar-week;
}
.@{fa-css-prefix}-camera:before {
  content: @fa-var-camera;
}
.@{fa-css-prefix}-camera-retro:before {
  content: @fa-var-camera-retro;
}
.@{fa-css-prefix}-campground:before {
  content: @fa-var-campground;
}
.@{fa-css-prefix}-canadian-maple-leaf:before {
  content: @fa-var-canadian-maple-leaf;
}
.@{fa-css-prefix}-candy-cane:before {
  content: @fa-var-candy-cane;
}
.@{fa-css-prefix}-cannabis:before {
  content: @fa-var-cannabis;
}
.@{fa-css-prefix}-capsules:before {
  content: @fa-var-capsules;
}
.@{fa-css-prefix}-car:before {
  content: @fa-var-car;
}
.@{fa-css-prefix}-car-alt:before {
  content: @fa-var-car-alt;
}
.@{fa-css-prefix}-car-battery:before {
  content: @fa-var-car-battery;
}
.@{fa-css-prefix}-car-crash:before {
  content: @fa-var-car-crash;
}
.@{fa-css-prefix}-car-side:before {
  content: @fa-var-car-side;
}
.@{fa-css-prefix}-caravan:before {
  content: @fa-var-caravan;
}
.@{fa-css-prefix}-caret-down:before {
  content: @fa-var-caret-down;
}
.@{fa-css-prefix}-caret-left:before {
  content: @fa-var-caret-left;
}
.@{fa-css-prefix}-caret-right:before {
  content: @fa-var-caret-right;
}
.@{fa-css-prefix}-caret-square-down:before {
  content: @fa-var-caret-square-down;
}
.@{fa-css-prefix}-caret-square-left:before {
  content: @fa-var-caret-square-left;
}
.@{fa-css-prefix}-caret-square-right:before {
  content: @fa-var-caret-square-right;
}
.@{fa-css-prefix}-caret-square-up:before {
  content: @fa-var-caret-square-up;
}
.@{fa-css-prefix}-caret-up:before {
  content: @fa-var-caret-up;
}
.@{fa-css-prefix}-carrot:before {
  content: @fa-var-carrot;
}
.@{fa-css-prefix}-cart-arrow-down:before {
  content: @fa-var-cart-arrow-down;
}
.@{fa-css-prefix}-cart-plus:before {
  content: @fa-var-cart-plus;
}
.@{fa-css-prefix}-cash-register:before {
  content: @fa-var-cash-register;
}
.@{fa-css-prefix}-cat:before {
  content: @fa-var-cat;
}
.@{fa-css-prefix}-cc-amazon-pay:before {
  content: @fa-var-cc-amazon-pay;
}
.@{fa-css-prefix}-cc-amex:before {
  content: @fa-var-cc-amex;
}
.@{fa-css-prefix}-cc-apple-pay:before {
  content: @fa-var-cc-apple-pay;
}
.@{fa-css-prefix}-cc-diners-club:before {
  content: @fa-var-cc-diners-club;
}
.@{fa-css-prefix}-cc-discover:before {
  content: @fa-var-cc-discover;
}
.@{fa-css-prefix}-cc-jcb:before {
  content: @fa-var-cc-jcb;
}
.@{fa-css-prefix}-cc-mastercard:before {
  content: @fa-var-cc-mastercard;
}
.@{fa-css-prefix}-cc-paypal:before {
  content: @fa-var-cc-paypal;
}
.@{fa-css-prefix}-cc-stripe:before {
  content: @fa-var-cc-stripe;
}
.@{fa-css-prefix}-cc-visa:before {
  content: @fa-var-cc-visa;
}
.@{fa-css-prefix}-centercode:before {
  content: @fa-var-centercode;
}
.@{fa-css-prefix}-centos:before {
  content: @fa-var-centos;
}
.@{fa-css-prefix}-certificate:before {
  content: @fa-var-certificate;
}
.@{fa-css-prefix}-chair:before {
  content: @fa-var-chair;
}
.@{fa-css-prefix}-chalkboard:before {
  content: @fa-var-chalkboard;
}
.@{fa-css-prefix}-chalkboard-teacher:before {
  content: @fa-var-chalkboard-teacher;
}
.@{fa-css-prefix}-charging-station:before {
  content: @fa-var-charging-station;
}
.@{fa-css-prefix}-chart-area:before {
  content: @fa-var-chart-area;
}
.@{fa-css-prefix}-chart-bar:before {
  content: @fa-var-chart-bar;
}
.@{fa-css-prefix}-chart-line:before {
  content: @fa-var-chart-line;
}
.@{fa-css-prefix}-chart-pie:before {
  content: @fa-var-chart-pie;
}
.@{fa-css-prefix}-check:before {
  content: @fa-var-check;
}
.@{fa-css-prefix}-check-circle:before {
  content: @fa-var-check-circle;
}
.@{fa-css-prefix}-check-double:before {
  content: @fa-var-check-double;
}
.@{fa-css-prefix}-check-square:before {
  content: @fa-var-check-square;
}
.@{fa-css-prefix}-cheese:before {
  content: @fa-var-cheese;
}
.@{fa-css-prefix}-chess:before {
  content: @fa-var-chess;
}
.@{fa-css-prefix}-chess-bishop:before {
  content: @fa-var-chess-bishop;
}
.@{fa-css-prefix}-chess-board:before {
  content: @fa-var-chess-board;
}
.@{fa-css-prefix}-chess-king:before {
  content: @fa-var-chess-king;
}
.@{fa-css-prefix}-chess-knight:before {
  content: @fa-var-chess-knight;
}
.@{fa-css-prefix}-chess-pawn:before {
  content: @fa-var-chess-pawn;
}
.@{fa-css-prefix}-chess-queen:before {
  content: @fa-var-chess-queen;
}
.@{fa-css-prefix}-chess-rook:before {
  content: @fa-var-chess-rook;
}
.@{fa-css-prefix}-chevron-circle-down:before {
  content: @fa-var-chevron-circle-down;
}
.@{fa-css-prefix}-chevron-circle-left:before {
  content: @fa-var-chevron-circle-left;
}
.@{fa-css-prefix}-chevron-circle-right:before {
  content: @fa-var-chevron-circle-right;
}
.@{fa-css-prefix}-chevron-circle-up:before {
  content: @fa-var-chevron-circle-up;
}
.@{fa-css-prefix}-chevron-down:before {
  content: @fa-var-chevron-down;
}
.@{fa-css-prefix}-chevron-left:before {
  content: @fa-var-chevron-left;
}
.@{fa-css-prefix}-chevron-right:before {
  content: @fa-var-chevron-right;
}
.@{fa-css-prefix}-chevron-up:before {
  content: @fa-var-chevron-up;
}
.@{fa-css-prefix}-child:before {
  content: @fa-var-child;
}
.@{fa-css-prefix}-chrome:before {
  content: @fa-var-chrome;
}
.@{fa-css-prefix}-chromecast:before {
  content: @fa-var-chromecast;
}
.@{fa-css-prefix}-church:before {
  content: @fa-var-church;
}
.@{fa-css-prefix}-circle:before {
  content: @fa-var-circle;
}
.@{fa-css-prefix}-circle-notch:before {
  content: @fa-var-circle-notch;
}
.@{fa-css-prefix}-city:before {
  content: @fa-var-city;
}
.@{fa-css-prefix}-clinic-medical:before {
  content: @fa-var-clinic-medical;
}
.@{fa-css-prefix}-clipboard:before {
  content: @fa-var-clipboard;
}
.@{fa-css-prefix}-clipboard-check:before {
  content: @fa-var-clipboard-check;
}
.@{fa-css-prefix}-clipboard-list:before {
  content: @fa-var-clipboard-list;
}
.@{fa-css-prefix}-clock:before {
  content: @fa-var-clock;
}
.@{fa-css-prefix}-clone:before {
  content: @fa-var-clone;
}
.@{fa-css-prefix}-closed-captioning:before {
  content: @fa-var-closed-captioning;
}
.@{fa-css-prefix}-cloud:before {
  content: @fa-var-cloud;
}
.@{fa-css-prefix}-cloud-download-alt:before {
  content: @fa-var-cloud-download-alt;
}
.@{fa-css-prefix}-cloud-meatball:before {
  content: @fa-var-cloud-meatball;
}
.@{fa-css-prefix}-cloud-moon:before {
  content: @fa-var-cloud-moon;
}
.@{fa-css-prefix}-cloud-moon-rain:before {
  content: @fa-var-cloud-moon-rain;
}
.@{fa-css-prefix}-cloud-rain:before {
  content: @fa-var-cloud-rain;
}
.@{fa-css-prefix}-cloud-showers-heavy:before {
  content: @fa-var-cloud-showers-heavy;
}
.@{fa-css-prefix}-cloud-sun:before {
  content: @fa-var-cloud-sun;
}
.@{fa-css-prefix}-cloud-sun-rain:before {
  content: @fa-var-cloud-sun-rain;
}
.@{fa-css-prefix}-cloud-upload-alt:before {
  content: @fa-var-cloud-upload-alt;
}
.@{fa-css-prefix}-cloudflare:before {
  content: @fa-var-cloudflare;
}
.@{fa-css-prefix}-cloudscale:before {
  content: @fa-var-cloudscale;
}
.@{fa-css-prefix}-cloudsmith:before {
  content: @fa-var-cloudsmith;
}
.@{fa-css-prefix}-cloudversify:before {
  content: @fa-var-cloudversify;
}
.@{fa-css-prefix}-cocktail:before {
  content: @fa-var-cocktail;
}
.@{fa-css-prefix}-code:before {
  content: @fa-var-code;
}
.@{fa-css-prefix}-code-branch:before {
  content: @fa-var-code-branch;
}
.@{fa-css-prefix}-codepen:before {
  content: @fa-var-codepen;
}
.@{fa-css-prefix}-codiepie:before {
  content: @fa-var-codiepie;
}
.@{fa-css-prefix}-coffee:before {
  content: @fa-var-coffee;
}
.@{fa-css-prefix}-cog:before {
  content: @fa-var-cog;
}
.@{fa-css-prefix}-cogs:before {
  content: @fa-var-cogs;
}
.@{fa-css-prefix}-coins:before {
  content: @fa-var-coins;
}
.@{fa-css-prefix}-columns:before {
  content: @fa-var-columns;
}
.@{fa-css-prefix}-comment:before {
  content: @fa-var-comment;
}
.@{fa-css-prefix}-comment-alt:before {
  content: @fa-var-comment-alt;
}
.@{fa-css-prefix}-comment-dollar:before {
  content: @fa-var-comment-dollar;
}
.@{fa-css-prefix}-comment-dots:before {
  content: @fa-var-comment-dots;
}
.@{fa-css-prefix}-comment-medical:before {
  content: @fa-var-comment-medical;
}
.@{fa-css-prefix}-comment-slash:before {
  content: @fa-var-comment-slash;
}
.@{fa-css-prefix}-comments:before {
  content: @fa-var-comments;
}
.@{fa-css-prefix}-comments-dollar:before {
  content: @fa-var-comments-dollar;
}
.@{fa-css-prefix}-compact-disc:before {
  content: @fa-var-compact-disc;
}
.@{fa-css-prefix}-compass:before {
  content: @fa-var-compass;
}
.@{fa-css-prefix}-compress:before {
  content: @fa-var-compress;
}
.@{fa-css-prefix}-compress-alt:before {
  content: @fa-var-compress-alt;
}
.@{fa-css-prefix}-compress-arrows-alt:before {
  content: @fa-var-compress-arrows-alt;
}
.@{fa-css-prefix}-concierge-bell:before {
  content: @fa-var-concierge-bell;
}
.@{fa-css-prefix}-confluence:before {
  content: @fa-var-confluence;
}
.@{fa-css-prefix}-connectdevelop:before {
  content: @fa-var-connectdevelop;
}
.@{fa-css-prefix}-contao:before {
  content: @fa-var-contao;
}
.@{fa-css-prefix}-cookie:before {
  content: @fa-var-cookie;
}
.@{fa-css-prefix}-cookie-bite:before {
  content: @fa-var-cookie-bite;
}
.@{fa-css-prefix}-copy:before {
  content: @fa-var-copy;
}
.@{fa-css-prefix}-copyright:before {
  content: @fa-var-copyright;
}
.@{fa-css-prefix}-cotton-bureau:before {
  content: @fa-var-cotton-bureau;
}
.@{fa-css-prefix}-couch:before {
  content: @fa-var-couch;
}
.@{fa-css-prefix}-cpanel:before {
  content: @fa-var-cpanel;
}
.@{fa-css-prefix}-creative-commons:before {
  content: @fa-var-creative-commons;
}
.@{fa-css-prefix}-creative-commons-by:before {
  content: @fa-var-creative-commons-by;
}
.@{fa-css-prefix}-creative-commons-nc:before {
  content: @fa-var-creative-commons-nc;
}
.@{fa-css-prefix}-creative-commons-nc-eu:before {
  content: @fa-var-creative-commons-nc-eu;
}
.@{fa-css-prefix}-creative-commons-nc-jp:before {
  content: @fa-var-creative-commons-nc-jp;
}
.@{fa-css-prefix}-creative-commons-nd:before {
  content: @fa-var-creative-commons-nd;
}
.@{fa-css-prefix}-creative-commons-pd:before {
  content: @fa-var-creative-commons-pd;
}
.@{fa-css-prefix}-creative-commons-pd-alt:before {
  content: @fa-var-creative-commons-pd-alt;
}
.@{fa-css-prefix}-creative-commons-remix:before {
  content: @fa-var-creative-commons-remix;
}
.@{fa-css-prefix}-creative-commons-sa:before {
  content: @fa-var-creative-commons-sa;
}
.@{fa-css-prefix}-creative-commons-sampling:before {
  content: @fa-var-creative-commons-sampling;
}
.@{fa-css-prefix}-creative-commons-sampling-plus:before {
  content: @fa-var-creative-commons-sampling-plus;
}
.@{fa-css-prefix}-creative-commons-share:before {
  content: @fa-var-creative-commons-share;
}
.@{fa-css-prefix}-creative-commons-zero:before {
  content: @fa-var-creative-commons-zero;
}
.@{fa-css-prefix}-credit-card:before {
  content: @fa-var-credit-card;
}
.@{fa-css-prefix}-critical-role:before {
  content: @fa-var-critical-role;
}
.@{fa-css-prefix}-crop:before {
  content: @fa-var-crop;
}
.@{fa-css-prefix}-crop-alt:before {
  content: @fa-var-crop-alt;
}
.@{fa-css-prefix}-cross:before {
  content: @fa-var-cross;
}
.@{fa-css-prefix}-crosshairs:before {
  content: @fa-var-crosshairs;
}
.@{fa-css-prefix}-crow:before {
  content: @fa-var-crow;
}
.@{fa-css-prefix}-crown:before {
  content: @fa-var-crown;
}
.@{fa-css-prefix}-crutch:before {
  content: @fa-var-crutch;
}
.@{fa-css-prefix}-css3:before {
  content: @fa-var-css3;
}
.@{fa-css-prefix}-css3-alt:before {
  content: @fa-var-css3-alt;
}
.@{fa-css-prefix}-cube:before {
  content: @fa-var-cube;
}
.@{fa-css-prefix}-cubes:before {
  content: @fa-var-cubes;
}
.@{fa-css-prefix}-cut:before {
  content: @fa-var-cut;
}
.@{fa-css-prefix}-cuttlefish:before {
  content: @fa-var-cuttlefish;
}
.@{fa-css-prefix}-d-and-d:before {
  content: @fa-var-d-and-d;
}
.@{fa-css-prefix}-d-and-d-beyond:before {
  content: @fa-var-d-and-d-beyond;
}
.@{fa-css-prefix}-dailymotion:before {
  content: @fa-var-dailymotion;
}
.@{fa-css-prefix}-dashcube:before {
  content: @fa-var-dashcube;
}
.@{fa-css-prefix}-database:before {
  content: @fa-var-database;
}
.@{fa-css-prefix}-deaf:before {
  content: @fa-var-deaf;
}
.@{fa-css-prefix}-deezer:before {
  content: @fa-var-deezer;
}
.@{fa-css-prefix}-delicious:before {
  content: @fa-var-delicious;
}
.@{fa-css-prefix}-democrat:before {
  content: @fa-var-democrat;
}
.@{fa-css-prefix}-deploydog:before {
  content: @fa-var-deploydog;
}
.@{fa-css-prefix}-deskpro:before {
  content: @fa-var-deskpro;
}
.@{fa-css-prefix}-desktop:before {
  content: @fa-var-desktop;
}
.@{fa-css-prefix}-dev:before {
  content: @fa-var-dev;
}
.@{fa-css-prefix}-deviantart:before {
  content: @fa-var-deviantart;
}
.@{fa-css-prefix}-dharmachakra:before {
  content: @fa-var-dharmachakra;
}
.@{fa-css-prefix}-dhl:before {
  content: @fa-var-dhl;
}
.@{fa-css-prefix}-diagnoses:before {
  content: @fa-var-diagnoses;
}
.@{fa-css-prefix}-diaspora:before {
  content: @fa-var-diaspora;
}
.@{fa-css-prefix}-dice:before {
  content: @fa-var-dice;
}
.@{fa-css-prefix}-dice-d20:before {
  content: @fa-var-dice-d20;
}
.@{fa-css-prefix}-dice-d6:before {
  content: @fa-var-dice-d6;
}
.@{fa-css-prefix}-dice-five:before {
  content: @fa-var-dice-five;
}
.@{fa-css-prefix}-dice-four:before {
  content: @fa-var-dice-four;
}
.@{fa-css-prefix}-dice-one:before {
  content: @fa-var-dice-one;
}
.@{fa-css-prefix}-dice-six:before {
  content: @fa-var-dice-six;
}
.@{fa-css-prefix}-dice-three:before {
  content: @fa-var-dice-three;
}
.@{fa-css-prefix}-dice-two:before {
  content: @fa-var-dice-two;
}
.@{fa-css-prefix}-digg:before {
  content: @fa-var-digg;
}
.@{fa-css-prefix}-digital-ocean:before {
  content: @fa-var-digital-ocean;
}
.@{fa-css-prefix}-digital-tachograph:before {
  content: @fa-var-digital-tachograph;
}
.@{fa-css-prefix}-directions:before {
  content: @fa-var-directions;
}
.@{fa-css-prefix}-discord:before {
  content: @fa-var-discord;
}
.@{fa-css-prefix}-discourse:before {
  content: @fa-var-discourse;
}
.@{fa-css-prefix}-disease:before {
  content: @fa-var-disease;
}
.@{fa-css-prefix}-divide:before {
  content: @fa-var-divide;
}
.@{fa-css-prefix}-dizzy:before {
  content: @fa-var-dizzy;
}
.@{fa-css-prefix}-dna:before {
  content: @fa-var-dna;
}
.@{fa-css-prefix}-dochub:before {
  content: @fa-var-dochub;
}
.@{fa-css-prefix}-docker:before {
  content: @fa-var-docker;
}
.@{fa-css-prefix}-dog:before {
  content: @fa-var-dog;
}
.@{fa-css-prefix}-dollar-sign:before {
  content: @fa-var-dollar-sign;
}
.@{fa-css-prefix}-dolly:before {
  content: @fa-var-dolly;
}
.@{fa-css-prefix}-dolly-flatbed:before {
  content: @fa-var-dolly-flatbed;
}
.@{fa-css-prefix}-donate:before {
  content: @fa-var-donate;
}
.@{fa-css-prefix}-door-closed:before {
  content: @fa-var-door-closed;
}
.@{fa-css-prefix}-door-open:before {
  content: @fa-var-door-open;
}
.@{fa-css-prefix}-dot-circle:before {
  content: @fa-var-dot-circle;
}
.@{fa-css-prefix}-dove:before {
  content: @fa-var-dove;
}
.@{fa-css-prefix}-download:before {
  content: @fa-var-download;
}
.@{fa-css-prefix}-draft2digital:before {
  content: @fa-var-draft2digital;
}
.@{fa-css-prefix}-drafting-compass:before {
  content: @fa-var-drafting-compass;
}
.@{fa-css-prefix}-dragon:before {
  content: @fa-var-dragon;
}
.@{fa-css-prefix}-draw-polygon:before {
  content: @fa-var-draw-polygon;
}
.@{fa-css-prefix}-dribbble:before {
  content: @fa-var-dribbble;
}
.@{fa-css-prefix}-dribbble-square:before {
  content: @fa-var-dribbble-square;
}
.@{fa-css-prefix}-dropbox:before {
  content: @fa-var-dropbox;
}
.@{fa-css-prefix}-drum:before {
  content: @fa-var-drum;
}
.@{fa-css-prefix}-drum-steelpan:before {
  content: @fa-var-drum-steelpan;
}
.@{fa-css-prefix}-drumstick-bite:before {
  content: @fa-var-drumstick-bite;
}
.@{fa-css-prefix}-drupal:before {
  content: @fa-var-drupal;
}
.@{fa-css-prefix}-dumbbell:before {
  content: @fa-var-dumbbell;
}
.@{fa-css-prefix}-dumpster:before {
  content: @fa-var-dumpster;
}
.@{fa-css-prefix}-dumpster-fire:before {
  content: @fa-var-dumpster-fire;
}
.@{fa-css-prefix}-dungeon:before {
  content: @fa-var-dungeon;
}
.@{fa-css-prefix}-dyalog:before {
  content: @fa-var-dyalog;
}
.@{fa-css-prefix}-earlybirds:before {
  content: @fa-var-earlybirds;
}
.@{fa-css-prefix}-ebay:before {
  content: @fa-var-ebay;
}
.@{fa-css-prefix}-edge:before {
  content: @fa-var-edge;
}
.@{fa-css-prefix}-edge-legacy:before {
  content: @fa-var-edge-legacy;
}
.@{fa-css-prefix}-edit:before {
  content: @fa-var-edit;
}
.@{fa-css-prefix}-egg:before {
  content: @fa-var-egg;
}
.@{fa-css-prefix}-eject:before {
  content: @fa-var-eject;
}
.@{fa-css-prefix}-elementor:before {
  content: @fa-var-elementor;
}
.@{fa-css-prefix}-ellipsis-h:before {
  content: @fa-var-ellipsis-h;
}
.@{fa-css-prefix}-ellipsis-v:before {
  content: @fa-var-ellipsis-v;
}
.@{fa-css-prefix}-ello:before {
  content: @fa-var-ello;
}
.@{fa-css-prefix}-ember:before {
  content: @fa-var-ember;
}
.@{fa-css-prefix}-empire:before {
  content: @fa-var-empire;
}
.@{fa-css-prefix}-envelope:before {
  content: @fa-var-envelope;
}
.@{fa-css-prefix}-envelope-open:before {
  content: @fa-var-envelope-open;
}
.@{fa-css-prefix}-envelope-open-text:before {
  content: @fa-var-envelope-open-text;
}
.@{fa-css-prefix}-envelope-square:before {
  content: @fa-var-envelope-square;
}
.@{fa-css-prefix}-envira:before {
  content: @fa-var-envira;
}
.@{fa-css-prefix}-equals:before {
  content: @fa-var-equals;
}
.@{fa-css-prefix}-eraser:before {
  content: @fa-var-eraser;
}
.@{fa-css-prefix}-erlang:before {
  content: @fa-var-erlang;
}
.@{fa-css-prefix}-ethereum:before {
  content: @fa-var-ethereum;
}
.@{fa-css-prefix}-ethernet:before {
  content: @fa-var-ethernet;
}
.@{fa-css-prefix}-etsy:before {
  content: @fa-var-etsy;
}
.@{fa-css-prefix}-euro-sign:before {
  content: @fa-var-euro-sign;
}
.@{fa-css-prefix}-evernote:before {
  content: @fa-var-evernote;
}
.@{fa-css-prefix}-exchange-alt:before {
  content: @fa-var-exchange-alt;
}
.@{fa-css-prefix}-exclamation:before {
  content: @fa-var-exclamation;
}
.@{fa-css-prefix}-exclamation-circle:before {
  content: @fa-var-exclamation-circle;
}
.@{fa-css-prefix}-exclamation-triangle:before {
  content: @fa-var-exclamation-triangle;
}
.@{fa-css-prefix}-expand:before {
  content: @fa-var-expand;
}
.@{fa-css-prefix}-expand-alt:before {
  content: @fa-var-expand-alt;
}
.@{fa-css-prefix}-expand-arrows-alt:before {
  content: @fa-var-expand-arrows-alt;
}
.@{fa-css-prefix}-expeditedssl:before {
  content: @fa-var-expeditedssl;
}
.@{fa-css-prefix}-external-link-alt:before {
  content: @fa-var-external-link-alt;
}
.@{fa-css-prefix}-external-link-square-alt:before {
  content: @fa-var-external-link-square-alt;
}
.@{fa-css-prefix}-eye:before {
  content: @fa-var-eye;
}
.@{fa-css-prefix}-eye-dropper:before {
  content: @fa-var-eye-dropper;
}
.@{fa-css-prefix}-eye-slash:before {
  content: @fa-var-eye-slash;
}
.@{fa-css-prefix}-facebook:before {
  content: @fa-var-facebook;
}
.@{fa-css-prefix}-facebook-f:before {
  content: @fa-var-facebook-f;
}
.@{fa-css-prefix}-facebook-messenger:before {
  content: @fa-var-facebook-messenger;
}
.@{fa-css-prefix}-facebook-square:before {
  content: @fa-var-facebook-square;
}
.@{fa-css-prefix}-fan:before {
  content: @fa-var-fan;
}
.@{fa-css-prefix}-fantasy-flight-games:before {
  content: @fa-var-fantasy-flight-games;
}
.@{fa-css-prefix}-fast-backward:before {
  content: @fa-var-fast-backward;
}
.@{fa-css-prefix}-fast-forward:before {
  content: @fa-var-fast-forward;
}
.@{fa-css-prefix}-faucet:before {
  content: @fa-var-faucet;
}
.@{fa-css-prefix}-fax:before {
  content: @fa-var-fax;
}
.@{fa-css-prefix}-feather:before {
  content: @fa-var-feather;
}
.@{fa-css-prefix}-feather-alt:before {
  content: @fa-var-feather-alt;
}
.@{fa-css-prefix}-fedex:before {
  content: @fa-var-fedex;
}
.@{fa-css-prefix}-fedora:before {
  content: @fa-var-fedora;
}
.@{fa-css-prefix}-female:before {
  content: @fa-var-female;
}
.@{fa-css-prefix}-fighter-jet:before {
  content: @fa-var-fighter-jet;
}
.@{fa-css-prefix}-figma:before {
  content: @fa-var-figma;
}
.@{fa-css-prefix}-file:before {
  content: @fa-var-file;
}
.@{fa-css-prefix}-file-alt:before {
  content: @fa-var-file-alt;
}
.@{fa-css-prefix}-file-archive:before {
  content: @fa-var-file-archive;
}
.@{fa-css-prefix}-file-audio:before {
  content: @fa-var-file-audio;
}
.@{fa-css-prefix}-file-code:before {
  content: @fa-var-file-code;
}
.@{fa-css-prefix}-file-contract:before {
  content: @fa-var-file-contract;
}
.@{fa-css-prefix}-file-csv:before {
  content: @fa-var-file-csv;
}
.@{fa-css-prefix}-file-download:before {
  content: @fa-var-file-download;
}
.@{fa-css-prefix}-file-excel:before {
  content: @fa-var-file-excel;
}
.@{fa-css-prefix}-file-export:before {
  content: @fa-var-file-export;
}
.@{fa-css-prefix}-file-image:before {
  content: @fa-var-file-image;
}
.@{fa-css-prefix}-file-import:before {
  content: @fa-var-file-import;
}
.@{fa-css-prefix}-file-invoice:before {
  content: @fa-var-file-invoice;
}
.@{fa-css-prefix}-file-invoice-dollar:before {
  content: @fa-var-file-invoice-dollar;
}
.@{fa-css-prefix}-file-medical:before {
  content: @fa-var-file-medical;
}
.@{fa-css-prefix}-file-medical-alt:before {
  content: @fa-var-file-medical-alt;
}
.@{fa-css-prefix}-file-pdf:before {
  content: @fa-var-file-pdf;
}
.@{fa-css-prefix}-file-powerpoint:before {
  content: @fa-var-file-powerpoint;
}
.@{fa-css-prefix}-file-prescription:before {
  content: @fa-var-file-prescription;
}
.@{fa-css-prefix}-file-signature:before {
  content: @fa-var-file-signature;
}
.@{fa-css-prefix}-file-upload:before {
  content: @fa-var-file-upload;
}
.@{fa-css-prefix}-file-video:before {
  content: @fa-var-file-video;
}
.@{fa-css-prefix}-file-word:before {
  content: @fa-var-file-word;
}
.@{fa-css-prefix}-fill:before {
  content: @fa-var-fill;
}
.@{fa-css-prefix}-fill-drip:before {
  content: @fa-var-fill-drip;
}
.@{fa-css-prefix}-film:before {
  content: @fa-var-film;
}
.@{fa-css-prefix}-filter:before {
  content: @fa-var-filter;
}
.@{fa-css-prefix}-fingerprint:before {
  content: @fa-var-fingerprint;
}
.@{fa-css-prefix}-fire:before {
  content: @fa-var-fire;
}
.@{fa-css-prefix}-fire-alt:before {
  content: @fa-var-fire-alt;
}
.@{fa-css-prefix}-fire-extinguisher:before {
  content: @fa-var-fire-extinguisher;
}
.@{fa-css-prefix}-firefox:before {
  content: @fa-var-firefox;
}
.@{fa-css-prefix}-firefox-browser:before {
  content: @fa-var-firefox-browser;
}
.@{fa-css-prefix}-first-aid:before {
  content: @fa-var-first-aid;
}
.@{fa-css-prefix}-first-order:before {
  content: @fa-var-first-order;
}
.@{fa-css-prefix}-first-order-alt:before {
  content: @fa-var-first-order-alt;
}
.@{fa-css-prefix}-firstdraft:before {
  content: @fa-var-firstdraft;
}
.@{fa-css-prefix}-fish:before {
  content: @fa-var-fish;
}
.@{fa-css-prefix}-fist-raised:before {
  content: @fa-var-fist-raised;
}
.@{fa-css-prefix}-flag:before {
  content: @fa-var-flag;
}
.@{fa-css-prefix}-flag-checkered:before {
  content: @fa-var-flag-checkered;
}
.@{fa-css-prefix}-flag-usa:before {
  content: @fa-var-flag-usa;
}
.@{fa-css-prefix}-flask:before {
  content: @fa-var-flask;
}
.@{fa-css-prefix}-flickr:before {
  content: @fa-var-flickr;
}
.@{fa-css-prefix}-flipboard:before {
  content: @fa-var-flipboard;
}
.@{fa-css-prefix}-flushed:before {
  content: @fa-var-flushed;
}
.@{fa-css-prefix}-fly:before {
  content: @fa-var-fly;
}
.@{fa-css-prefix}-folder:before {
  content: @fa-var-folder;
}
.@{fa-css-prefix}-folder-minus:before {
  content: @fa-var-folder-minus;
}
.@{fa-css-prefix}-folder-open:before {
  content: @fa-var-folder-open;
}
.@{fa-css-prefix}-folder-plus:before {
  content: @fa-var-folder-plus;
}
.@{fa-css-prefix}-font:before {
  content: @fa-var-font;
}
.@{fa-css-prefix}-font-awesome:before {
  content: @fa-var-font-awesome;
}
.@{fa-css-prefix}-font-awesome-alt:before {
  content: @fa-var-font-awesome-alt;
}
.@{fa-css-prefix}-font-awesome-flag:before {
  content: @fa-var-font-awesome-flag;
}
.@{fa-css-prefix}-font-awesome-logo-full:before {
  content: @fa-var-font-awesome-logo-full;
}
.@{fa-css-prefix}-fonticons:before {
  content: @fa-var-fonticons;
}
.@{fa-css-prefix}-fonticons-fi:before {
  content: @fa-var-fonticons-fi;
}
.@{fa-css-prefix}-football-ball:before {
  content: @fa-var-football-ball;
}
.@{fa-css-prefix}-fort-awesome:before {
  content: @fa-var-fort-awesome;
}
.@{fa-css-prefix}-fort-awesome-alt:before {
  content: @fa-var-fort-awesome-alt;
}
.@{fa-css-prefix}-forumbee:before {
  content: @fa-var-forumbee;
}
.@{fa-css-prefix}-forward:before {
  content: @fa-var-forward;
}
.@{fa-css-prefix}-foursquare:before {
  content: @fa-var-foursquare;
}
.@{fa-css-prefix}-free-code-camp:before {
  content: @fa-var-free-code-camp;
}
.@{fa-css-prefix}-freebsd:before {
  content: @fa-var-freebsd;
}
.@{fa-css-prefix}-frog:before {
  content: @fa-var-frog;
}
.@{fa-css-prefix}-frown:before {
  content: @fa-var-frown;
}
.@{fa-css-prefix}-frown-open:before {
  content: @fa-var-frown-open;
}
.@{fa-css-prefix}-fulcrum:before {
  content: @fa-var-fulcrum;
}
.@{fa-css-prefix}-funnel-dollar:before {
  content: @fa-var-funnel-dollar;
}
.@{fa-css-prefix}-futbol:before {
  content: @fa-var-futbol;
}
.@{fa-css-prefix}-galactic-republic:before {
  content: @fa-var-galactic-republic;
}
.@{fa-css-prefix}-galactic-senate:before {
  content: @fa-var-galactic-senate;
}
.@{fa-css-prefix}-gamepad:before {
  content: @fa-var-gamepad;
}
.@{fa-css-prefix}-gas-pump:before {
  content: @fa-var-gas-pump;
}
.@{fa-css-prefix}-gavel:before {
  content: @fa-var-gavel;
}
.@{fa-css-prefix}-gem:before {
  content: @fa-var-gem;
}
.@{fa-css-prefix}-genderless:before {
  content: @fa-var-genderless;
}
.@{fa-css-prefix}-get-pocket:before {
  content: @fa-var-get-pocket;
}
.@{fa-css-prefix}-gg:before {
  content: @fa-var-gg;
}
.@{fa-css-prefix}-gg-circle:before {
  content: @fa-var-gg-circle;
}
.@{fa-css-prefix}-ghost:before {
  content: @fa-var-ghost;
}
.@{fa-css-prefix}-gift:before {
  content: @fa-var-gift;
}
.@{fa-css-prefix}-gifts:before {
  content: @fa-var-gifts;
}
.@{fa-css-prefix}-git:before {
  content: @fa-var-git;
}
.@{fa-css-prefix}-git-alt:before {
  content: @fa-var-git-alt;
}
.@{fa-css-prefix}-git-square:before {
  content: @fa-var-git-square;
}
.@{fa-css-prefix}-github:before {
  content: @fa-var-github;
}
.@{fa-css-prefix}-github-alt:before {
  content: @fa-var-github-alt;
}
.@{fa-css-prefix}-github-square:before {
  content: @fa-var-github-square;
}
.@{fa-css-prefix}-gitkraken:before {
  content: @fa-var-gitkraken;
}
.@{fa-css-prefix}-gitlab:before {
  content: @fa-var-gitlab;
}
.@{fa-css-prefix}-gitter:before {
  content: @fa-var-gitter;
}
.@{fa-css-prefix}-glass-cheers:before {
  content: @fa-var-glass-cheers;
}
.@{fa-css-prefix}-glass-martini:before {
  content: @fa-var-glass-martini;
}
.@{fa-css-prefix}-glass-martini-alt:before {
  content: @fa-var-glass-martini-alt;
}
.@{fa-css-prefix}-glass-whiskey:before {
  content: @fa-var-glass-whiskey;
}
.@{fa-css-prefix}-glasses:before {
  content: @fa-var-glasses;
}
.@{fa-css-prefix}-glide:before {
  content: @fa-var-glide;
}
.@{fa-css-prefix}-glide-g:before {
  content: @fa-var-glide-g;
}
.@{fa-css-prefix}-globe:before {
  content: @fa-var-globe;
}
.@{fa-css-prefix}-globe-africa:before {
  content: @fa-var-globe-africa;
}
.@{fa-css-prefix}-globe-americas:before {
  content: @fa-var-globe-americas;
}
.@{fa-css-prefix}-globe-asia:before {
  content: @fa-var-globe-asia;
}
.@{fa-css-prefix}-globe-europe:before {
  content: @fa-var-globe-europe;
}
.@{fa-css-prefix}-gofore:before {
  content: @fa-var-gofore;
}
.@{fa-css-prefix}-golf-ball:before {
  content: @fa-var-golf-ball;
}
.@{fa-css-prefix}-goodreads:before {
  content: @fa-var-goodreads;
}
.@{fa-css-prefix}-goodreads-g:before {
  content: @fa-var-goodreads-g;
}
.@{fa-css-prefix}-google:before {
  content: @fa-var-google;
}
.@{fa-css-prefix}-google-drive:before {
  content: @fa-var-google-drive;
}
.@{fa-css-prefix}-google-pay:before {
  content: @fa-var-google-pay;
}
.@{fa-css-prefix}-google-play:before {
  content: @fa-var-google-play;
}
.@{fa-css-prefix}-google-plus:before {
  content: @fa-var-google-plus;
}
.@{fa-css-prefix}-google-plus-g:before {
  content: @fa-var-google-plus-g;
}
.@{fa-css-prefix}-google-plus-square:before {
  content: @fa-var-google-plus-square;
}
.@{fa-css-prefix}-google-wallet:before {
  content: @fa-var-google-wallet;
}
.@{fa-css-prefix}-gopuram:before {
  content: @fa-var-gopuram;
}
.@{fa-css-prefix}-graduation-cap:before {
  content: @fa-var-graduation-cap;
}
.@{fa-css-prefix}-gratipay:before {
  content: @fa-var-gratipay;
}
.@{fa-css-prefix}-grav:before {
  content: @fa-var-grav;
}
.@{fa-css-prefix}-greater-than:before {
  content: @fa-var-greater-than;
}
.@{fa-css-prefix}-greater-than-equal:before {
  content: @fa-var-greater-than-equal;
}
.@{fa-css-prefix}-grimace:before {
  content: @fa-var-grimace;
}
.@{fa-css-prefix}-grin:before {
  content: @fa-var-grin;
}
.@{fa-css-prefix}-grin-alt:before {
  content: @fa-var-grin-alt;
}
.@{fa-css-prefix}-grin-beam:before {
  content: @fa-var-grin-beam;
}
.@{fa-css-prefix}-grin-beam-sweat:before {
  content: @fa-var-grin-beam-sweat;
}
.@{fa-css-prefix}-grin-hearts:before {
  content: @fa-var-grin-hearts;
}
.@{fa-css-prefix}-grin-squint:before {
  content: @fa-var-grin-squint;
}
.@{fa-css-prefix}-grin-squint-tears:before {
  content: @fa-var-grin-squint-tears;
}
.@{fa-css-prefix}-grin-stars:before {
  content: @fa-var-grin-stars;
}
.@{fa-css-prefix}-grin-tears:before {
  content: @fa-var-grin-tears;
}
.@{fa-css-prefix}-grin-tongue:before {
  content: @fa-var-grin-tongue;
}
.@{fa-css-prefix}-grin-tongue-squint:before {
  content: @fa-var-grin-tongue-squint;
}
.@{fa-css-prefix}-grin-tongue-wink:before {
  content: @fa-var-grin-tongue-wink;
}
.@{fa-css-prefix}-grin-wink:before {
  content: @fa-var-grin-wink;
}
.@{fa-css-prefix}-grip-horizontal:before {
  content: @fa-var-grip-horizontal;
}
.@{fa-css-prefix}-grip-lines:before {
  content: @fa-var-grip-lines;
}
.@{fa-css-prefix}-grip-lines-vertical:before {
  content: @fa-var-grip-lines-vertical;
}
.@{fa-css-prefix}-grip-vertical:before {
  content: @fa-var-grip-vertical;
}
.@{fa-css-prefix}-gripfire:before {
  content: @fa-var-gripfire;
}
.@{fa-css-prefix}-grunt:before {
  content: @fa-var-grunt;
}
.@{fa-css-prefix}-guilded:before {
  content: @fa-var-guilded;
}
.@{fa-css-prefix}-guitar:before {
  content: @fa-var-guitar;
}
.@{fa-css-prefix}-gulp:before {
  content: @fa-var-gulp;
}
.@{fa-css-prefix}-h-square:before {
  content: @fa-var-h-square;
}
.@{fa-css-prefix}-hacker-news:before {
  content: @fa-var-hacker-news;
}
.@{fa-css-prefix}-hacker-news-square:before {
  content: @fa-var-hacker-news-square;
}
.@{fa-css-prefix}-hackerrank:before {
  content: @fa-var-hackerrank;
}
.@{fa-css-prefix}-hamburger:before {
  content: @fa-var-hamburger;
}
.@{fa-css-prefix}-hammer:before {
  content: @fa-var-hammer;
}
.@{fa-css-prefix}-hamsa:before {
  content: @fa-var-hamsa;
}
.@{fa-css-prefix}-hand-holding:before {
  content: @fa-var-hand-holding;
}
.@{fa-css-prefix}-hand-holding-heart:before {
  content: @fa-var-hand-holding-heart;
}
.@{fa-css-prefix}-hand-holding-medical:before {
  content: @fa-var-hand-holding-medical;
}
.@{fa-css-prefix}-hand-holding-usd:before {
  content: @fa-var-hand-holding-usd;
}
.@{fa-css-prefix}-hand-holding-water:before {
  content: @fa-var-hand-holding-water;
}
.@{fa-css-prefix}-hand-lizard:before {
  content: @fa-var-hand-lizard;
}
.@{fa-css-prefix}-hand-middle-finger:before {
  content: @fa-var-hand-middle-finger;
}
.@{fa-css-prefix}-hand-paper:before {
  content: @fa-var-hand-paper;
}
.@{fa-css-prefix}-hand-peace:before {
  content: @fa-var-hand-peace;
}
.@{fa-css-prefix}-hand-point-down:before {
  content: @fa-var-hand-point-down;
}
.@{fa-css-prefix}-hand-point-left:before {
  content: @fa-var-hand-point-left;
}
.@{fa-css-prefix}-hand-point-right:before {
  content: @fa-var-hand-point-right;
}
.@{fa-css-prefix}-hand-point-up:before {
  content: @fa-var-hand-point-up;
}
.@{fa-css-prefix}-hand-pointer:before {
  content: @fa-var-hand-pointer;
}
.@{fa-css-prefix}-hand-rock:before {
  content: @fa-var-hand-rock;
}
.@{fa-css-prefix}-hand-scissors:before {
  content: @fa-var-hand-scissors;
}
.@{fa-css-prefix}-hand-sparkles:before {
  content: @fa-var-hand-sparkles;
}
.@{fa-css-prefix}-hand-spock:before {
  content: @fa-var-hand-spock;
}
.@{fa-css-prefix}-hands:before {
  content: @fa-var-hands;
}
.@{fa-css-prefix}-hands-helping:before {
  content: @fa-var-hands-helping;
}
.@{fa-css-prefix}-hands-wash:before {
  content: @fa-var-hands-wash;
}
.@{fa-css-prefix}-handshake:before {
  content: @fa-var-handshake;
}
.@{fa-css-prefix}-handshake-alt-slash:before {
  content: @fa-var-handshake-alt-slash;
}
.@{fa-css-prefix}-handshake-slash:before {
  content: @fa-var-handshake-slash;
}
.@{fa-css-prefix}-hanukiah:before {
  content: @fa-var-hanukiah;
}
.@{fa-css-prefix}-hard-hat:before {
  content: @fa-var-hard-hat;
}
.@{fa-css-prefix}-hashtag:before {
  content: @fa-var-hashtag;
}
.@{fa-css-prefix}-hat-cowboy:before {
  content: @fa-var-hat-cowboy;
}
.@{fa-css-prefix}-hat-cowboy-side:before {
  content: @fa-var-hat-cowboy-side;
}
.@{fa-css-prefix}-hat-wizard:before {
  content: @fa-var-hat-wizard;
}
.@{fa-css-prefix}-hdd:before {
  content: @fa-var-hdd;
}
.@{fa-css-prefix}-head-side-cough:before {
  content: @fa-var-head-side-cough;
}
.@{fa-css-prefix}-head-side-cough-slash:before {
  content: @fa-var-head-side-cough-slash;
}
.@{fa-css-prefix}-head-side-mask:before {
  content: @fa-var-head-side-mask;
}
.@{fa-css-prefix}-head-side-virus:before {
  content: @fa-var-head-side-virus;
}
.@{fa-css-prefix}-heading:before {
  content: @fa-var-heading;
}
.@{fa-css-prefix}-headphones:before {
  content: @fa-var-headphones;
}
.@{fa-css-prefix}-headphones-alt:before {
  content: @fa-var-headphones-alt;
}
.@{fa-css-prefix}-headset:before {
  content: @fa-var-headset;
}
.@{fa-css-prefix}-heart:before {
  content: @fa-var-heart;
}
.@{fa-css-prefix}-heart-broken:before {
  content: @fa-var-heart-broken;
}
.@{fa-css-prefix}-heartbeat:before {
  content: @fa-var-heartbeat;
}
.@{fa-css-prefix}-helicopter:before {
  content: @fa-var-helicopter;
}
.@{fa-css-prefix}-highlighter:before {
  content: @fa-var-highlighter;
}
.@{fa-css-prefix}-hiking:before {
  content: @fa-var-hiking;
}
.@{fa-css-prefix}-hippo:before {
  content: @fa-var-hippo;
}
.@{fa-css-prefix}-hips:before {
  content: @fa-var-hips;
}
.@{fa-css-prefix}-hire-a-helper:before {
  content: @fa-var-hire-a-helper;
}
.@{fa-css-prefix}-history:before {
  content: @fa-var-history;
}
.@{fa-css-prefix}-hive:before {
  content: @fa-var-hive;
}
.@{fa-css-prefix}-hockey-puck:before {
  content: @fa-var-hockey-puck;
}
.@{fa-css-prefix}-holly-berry:before {
  content: @fa-var-holly-berry;
}
.@{fa-css-prefix}-home:before {
  content: @fa-var-home;
}
.@{fa-css-prefix}-hooli:before {
  content: @fa-var-hooli;
}
.@{fa-css-prefix}-hornbill:before {
  content: @fa-var-hornbill;
}
.@{fa-css-prefix}-horse:before {
  content: @fa-var-horse;
}
.@{fa-css-prefix}-horse-head:before {
  content: @fa-var-horse-head;
}
.@{fa-css-prefix}-hospital:before {
  content: @fa-var-hospital;
}
.@{fa-css-prefix}-hospital-alt:before {
  content: @fa-var-hospital-alt;
}
.@{fa-css-prefix}-hospital-symbol:before {
  content: @fa-var-hospital-symbol;
}
.@{fa-css-prefix}-hospital-user:before {
  content: @fa-var-hospital-user;
}
.@{fa-css-prefix}-hot-tub:before {
  content: @fa-var-hot-tub;
}
.@{fa-css-prefix}-hotdog:before {
  content: @fa-var-hotdog;
}
.@{fa-css-prefix}-hotel:before {
  content: @fa-var-hotel;
}
.@{fa-css-prefix}-hotjar:before {
  content: @fa-var-hotjar;
}
.@{fa-css-prefix}-hourglass:before {
  content: @fa-var-hourglass;
}
.@{fa-css-prefix}-hourglass-end:before {
  content: @fa-var-hourglass-end;
}
.@{fa-css-prefix}-hourglass-half:before {
  content: @fa-var-hourglass-half;
}
.@{fa-css-prefix}-hourglass-start:before {
  content: @fa-var-hourglass-start;
}
.@{fa-css-prefix}-house-damage:before {
  content: @fa-var-house-damage;
}
.@{fa-css-prefix}-house-user:before {
  content: @fa-var-house-user;
}
.@{fa-css-prefix}-houzz:before {
  content: @fa-var-houzz;
}
.@{fa-css-prefix}-hryvnia:before {
  content: @fa-var-hryvnia;
}
.@{fa-css-prefix}-html5:before {
  content: @fa-var-html5;
}
.@{fa-css-prefix}-hubspot:before {
  content: @fa-var-hubspot;
}
.@{fa-css-prefix}-i-cursor:before {
  content: @fa-var-i-cursor;
}
.@{fa-css-prefix}-ice-cream:before {
  content: @fa-var-ice-cream;
}
.@{fa-css-prefix}-icicles:before {
  content: @fa-var-icicles;
}
.@{fa-css-prefix}-icons:before {
  content: @fa-var-icons;
}
.@{fa-css-prefix}-id-badge:before {
  content: @fa-var-id-badge;
}
.@{fa-css-prefix}-id-card:before {
  content: @fa-var-id-card;
}
.@{fa-css-prefix}-id-card-alt:before {
  content: @fa-var-id-card-alt;
}
.@{fa-css-prefix}-ideal:before {
  content: @fa-var-ideal;
}
.@{fa-css-prefix}-igloo:before {
  content: @fa-var-igloo;
}
.@{fa-css-prefix}-image:before {
  content: @fa-var-image;
}
.@{fa-css-prefix}-images:before {
  content: @fa-var-images;
}
.@{fa-css-prefix}-imdb:before {
  content: @fa-var-imdb;
}
.@{fa-css-prefix}-inbox:before {
  content: @fa-var-inbox;
}
.@{fa-css-prefix}-indent:before {
  content: @fa-var-indent;
}
.@{fa-css-prefix}-industry:before {
  content: @fa-var-industry;
}
.@{fa-css-prefix}-infinity:before {
  content: @fa-var-infinity;
}
.@{fa-css-prefix}-info:before {
  content: @fa-var-info;
}
.@{fa-css-prefix}-info-circle:before {
  content: @fa-var-info-circle;
}
.@{fa-css-prefix}-innosoft:before {
  content: @fa-var-innosoft;
}
.@{fa-css-prefix}-instagram:before {
  content: @fa-var-instagram;
}
.@{fa-css-prefix}-instagram-square:before {
  content: @fa-var-instagram-square;
}
.@{fa-css-prefix}-instalod:before {
  content: @fa-var-instalod;
}
.@{fa-css-prefix}-intercom:before {
  content: @fa-var-intercom;
}
.@{fa-css-prefix}-internet-explorer:before {
  content: @fa-var-internet-explorer;
}
.@{fa-css-prefix}-invision:before {
  content: @fa-var-invision;
}
.@{fa-css-prefix}-ioxhost:before {
  content: @fa-var-ioxhost;
}
.@{fa-css-prefix}-italic:before {
  content: @fa-var-italic;
}
.@{fa-css-prefix}-itch-io:before {
  content: @fa-var-itch-io;
}
.@{fa-css-prefix}-itunes:before {
  content: @fa-var-itunes;
}
.@{fa-css-prefix}-itunes-note:before {
  content: @fa-var-itunes-note;
}
.@{fa-css-prefix}-java:before {
  content: @fa-var-java;
}
.@{fa-css-prefix}-jedi:before {
  content: @fa-var-jedi;
}
.@{fa-css-prefix}-jedi-order:before {
  content: @fa-var-jedi-order;
}
.@{fa-css-prefix}-jenkins:before {
  content: @fa-var-jenkins;
}
.@{fa-css-prefix}-jira:before {
  content: @fa-var-jira;
}
.@{fa-css-prefix}-joget:before {
  content: @fa-var-joget;
}
.@{fa-css-prefix}-joint:before {
  content: @fa-var-joint;
}
.@{fa-css-prefix}-joomla:before {
  content: @fa-var-joomla;
}
.@{fa-css-prefix}-journal-whills:before {
  content: @fa-var-journal-whills;
}
.@{fa-css-prefix}-js:before {
  content: @fa-var-js;
}
.@{fa-css-prefix}-js-square:before {
  content: @fa-var-js-square;
}
.@{fa-css-prefix}-jsfiddle:before {
  content: @fa-var-jsfiddle;
}
.@{fa-css-prefix}-kaaba:before {
  content: @fa-var-kaaba;
}
.@{fa-css-prefix}-kaggle:before {
  content: @fa-var-kaggle;
}
.@{fa-css-prefix}-key:before {
  content: @fa-var-key;
}
.@{fa-css-prefix}-keybase:before {
  content: @fa-var-keybase;
}
.@{fa-css-prefix}-keyboard:before {
  content: @fa-var-keyboard;
}
.@{fa-css-prefix}-keycdn:before {
  content: @fa-var-keycdn;
}
.@{fa-css-prefix}-khanda:before {
  content: @fa-var-khanda;
}
.@{fa-css-prefix}-kickstarter:before {
  content: @fa-var-kickstarter;
}
.@{fa-css-prefix}-kickstarter-k:before {
  content: @fa-var-kickstarter-k;
}
.@{fa-css-prefix}-kiss:before {
  content: @fa-var-kiss;
}
.@{fa-css-prefix}-kiss-beam:before {
  content: @fa-var-kiss-beam;
}
.@{fa-css-prefix}-kiss-wink-heart:before {
  content: @fa-var-kiss-wink-heart;
}
.@{fa-css-prefix}-kiwi-bird:before {
  content: @fa-var-kiwi-bird;
}
.@{fa-css-prefix}-korvue:before {
  content: @fa-var-korvue;
}
.@{fa-css-prefix}-landmark:before {
  content: @fa-var-landmark;
}
.@{fa-css-prefix}-language:before {
  content: @fa-var-language;
}
.@{fa-css-prefix}-laptop:before {
  content: @fa-var-laptop;
}
.@{fa-css-prefix}-laptop-code:before {
  content: @fa-var-laptop-code;
}
.@{fa-css-prefix}-laptop-house:before {
  content: @fa-var-laptop-house;
}
.@{fa-css-prefix}-laptop-medical:before {
  content: @fa-var-laptop-medical;
}
.@{fa-css-prefix}-laravel:before {
  content: @fa-var-laravel;
}
.@{fa-css-prefix}-lastfm:before {
  content: @fa-var-lastfm;
}
.@{fa-css-prefix}-lastfm-square:before {
  content: @fa-var-lastfm-square;
}
.@{fa-css-prefix}-laugh:before {
  content: @fa-var-laugh;
}
.@{fa-css-prefix}-laugh-beam:before {
  content: @fa-var-laugh-beam;
}
.@{fa-css-prefix}-laugh-squint:before {
  content: @fa-var-laugh-squint;
}
.@{fa-css-prefix}-laugh-wink:before {
  content: @fa-var-laugh-wink;
}
.@{fa-css-prefix}-layer-group:before {
  content: @fa-var-layer-group;
}
.@{fa-css-prefix}-leaf:before {
  content: @fa-var-leaf;
}
.@{fa-css-prefix}-leanpub:before {
  content: @fa-var-leanpub;
}
.@{fa-css-prefix}-lemon:before {
  content: @fa-var-lemon;
}
.@{fa-css-prefix}-less:before {
  content: @fa-var-less;
}
.@{fa-css-prefix}-less-than:before {
  content: @fa-var-less-than;
}
.@{fa-css-prefix}-less-than-equal:before {
  content: @fa-var-less-than-equal;
}
.@{fa-css-prefix}-level-down-alt:before {
  content: @fa-var-level-down-alt;
}
.@{fa-css-prefix}-level-up-alt:before {
  content: @fa-var-level-up-alt;
}
.@{fa-css-prefix}-life-ring:before {
  content: @fa-var-life-ring;
}
.@{fa-css-prefix}-lightbulb:before {
  content: @fa-var-lightbulb;
}
.@{fa-css-prefix}-line:before {
  content: @fa-var-line;
}
.@{fa-css-prefix}-link:before {
  content: @fa-var-link;
}
.@{fa-css-prefix}-linkedin:before {
  content: @fa-var-linkedin;
}
.@{fa-css-prefix}-linkedin-in:before {
  content: @fa-var-linkedin-in;
}
.@{fa-css-prefix}-linode:before {
  content: @fa-var-linode;
}
.@{fa-css-prefix}-linux:before {
  content: @fa-var-linux;
}
.@{fa-css-prefix}-lira-sign:before {
  content: @fa-var-lira-sign;
}
.@{fa-css-prefix}-list:before {
  content: @fa-var-list;
}
.@{fa-css-prefix}-list-alt:before {
  content: @fa-var-list-alt;
}
.@{fa-css-prefix}-list-ol:before {
  content: @fa-var-list-ol;
}
.@{fa-css-prefix}-list-ul:before {
  content: @fa-var-list-ul;
}
.@{fa-css-prefix}-location-arrow:before {
  content: @fa-var-location-arrow;
}
.@{fa-css-prefix}-lock:before {
  content: @fa-var-lock;
}
.@{fa-css-prefix}-lock-open:before {
  content: @fa-var-lock-open;
}
.@{fa-css-prefix}-long-arrow-alt-down:before {
  content: @fa-var-long-arrow-alt-down;
}
.@{fa-css-prefix}-long-arrow-alt-left:before {
  content: @fa-var-long-arrow-alt-left;
}
.@{fa-css-prefix}-long-arrow-alt-right:before {
  content: @fa-var-long-arrow-alt-right;
}
.@{fa-css-prefix}-long-arrow-alt-up:before {
  content: @fa-var-long-arrow-alt-up;
}
.@{fa-css-prefix}-low-vision:before {
  content: @fa-var-low-vision;
}
.@{fa-css-prefix}-luggage-cart:before {
  content: @fa-var-luggage-cart;
}
.@{fa-css-prefix}-lungs:before {
  content: @fa-var-lungs;
}
.@{fa-css-prefix}-lungs-virus:before {
  content: @fa-var-lungs-virus;
}
.@{fa-css-prefix}-lyft:before {
  content: @fa-var-lyft;
}
.@{fa-css-prefix}-magento:before {
  content: @fa-var-magento;
}
.@{fa-css-prefix}-magic:before {
  content: @fa-var-magic;
}
.@{fa-css-prefix}-magnet:before {
  content: @fa-var-magnet;
}
.@{fa-css-prefix}-mail-bulk:before {
  content: @fa-var-mail-bulk;
}
.@{fa-css-prefix}-mailchimp:before {
  content: @fa-var-mailchimp;
}
.@{fa-css-prefix}-male:before {
  content: @fa-var-male;
}
.@{fa-css-prefix}-mandalorian:before {
  content: @fa-var-mandalorian;
}
.@{fa-css-prefix}-map:before {
  content: @fa-var-map;
}
.@{fa-css-prefix}-map-marked:before {
  content: @fa-var-map-marked;
}
.@{fa-css-prefix}-map-marked-alt:before {
  content: @fa-var-map-marked-alt;
}
.@{fa-css-prefix}-map-marker:before {
  content: @fa-var-map-marker;
}
.@{fa-css-prefix}-map-marker-alt:before {
  content: @fa-var-map-marker-alt;
}
.@{fa-css-prefix}-map-pin:before {
  content: @fa-var-map-pin;
}
.@{fa-css-prefix}-map-signs:before {
  content: @fa-var-map-signs;
}
.@{fa-css-prefix}-markdown:before {
  content: @fa-var-markdown;
}
.@{fa-css-prefix}-marker:before {
  content: @fa-var-marker;
}
.@{fa-css-prefix}-mars:before {
  content: @fa-var-mars;
}
.@{fa-css-prefix}-mars-double:before {
  content: @fa-var-mars-double;
}
.@{fa-css-prefix}-mars-stroke:before {
  content: @fa-var-mars-stroke;
}
.@{fa-css-prefix}-mars-stroke-h:before {
  content: @fa-var-mars-stroke-h;
}
.@{fa-css-prefix}-mars-stroke-v:before {
  content: @fa-var-mars-stroke-v;
}
.@{fa-css-prefix}-mask:before {
  content: @fa-var-mask;
}
.@{fa-css-prefix}-mastodon:before {
  content: @fa-var-mastodon;
}
.@{fa-css-prefix}-maxcdn:before {
  content: @fa-var-maxcdn;
}
.@{fa-css-prefix}-mdb:before {
  content: @fa-var-mdb;
}
.@{fa-css-prefix}-medal:before {
  content: @fa-var-medal;
}
.@{fa-css-prefix}-medapps:before {
  content: @fa-var-medapps;
}
.@{fa-css-prefix}-medium:before {
  content: @fa-var-medium;
}
.@{fa-css-prefix}-medium-m:before {
  content: @fa-var-medium-m;
}
.@{fa-css-prefix}-medkit:before {
  content: @fa-var-medkit;
}
.@{fa-css-prefix}-medrt:before {
  content: @fa-var-medrt;
}
.@{fa-css-prefix}-meetup:before {
  content: @fa-var-meetup;
}
.@{fa-css-prefix}-megaport:before {
  content: @fa-var-megaport;
}
.@{fa-css-prefix}-meh:before {
  content: @fa-var-meh;
}
.@{fa-css-prefix}-meh-blank:before {
  content: @fa-var-meh-blank;
}
.@{fa-css-prefix}-meh-rolling-eyes:before {
  content: @fa-var-meh-rolling-eyes;
}
.@{fa-css-prefix}-memory:before {
  content: @fa-var-memory;
}
.@{fa-css-prefix}-mendeley:before {
  content: @fa-var-mendeley;
}
.@{fa-css-prefix}-menorah:before {
  content: @fa-var-menorah;
}
.@{fa-css-prefix}-mercury:before {
  content: @fa-var-mercury;
}
.@{fa-css-prefix}-meteor:before {
  content: @fa-var-meteor;
}
.@{fa-css-prefix}-microblog:before {
  content: @fa-var-microblog;
}
.@{fa-css-prefix}-microchip:before {
  content: @fa-var-microchip;
}
.@{fa-css-prefix}-microphone:before {
  content: @fa-var-microphone;
}
.@{fa-css-prefix}-microphone-alt:before {
  content: @fa-var-microphone-alt;
}
.@{fa-css-prefix}-microphone-alt-slash:before {
  content: @fa-var-microphone-alt-slash;
}
.@{fa-css-prefix}-microphone-slash:before {
  content: @fa-var-microphone-slash;
}
.@{fa-css-prefix}-microscope:before {
  content: @fa-var-microscope;
}
.@{fa-css-prefix}-microsoft:before {
  content: @fa-var-microsoft;
}
.@{fa-css-prefix}-minus:before {
  content: @fa-var-minus;
}
.@{fa-css-prefix}-minus-circle:before {
  content: @fa-var-minus-circle;
}
.@{fa-css-prefix}-minus-square:before {
  content: @fa-var-minus-square;
}
.@{fa-css-prefix}-mitten:before {
  content: @fa-var-mitten;
}
.@{fa-css-prefix}-mix:before {
  content: @fa-var-mix;
}
.@{fa-css-prefix}-mixcloud:before {
  content: @fa-var-mixcloud;
}
.@{fa-css-prefix}-mixer:before {
  content: @fa-var-mixer;
}
.@{fa-css-prefix}-mizuni:before {
  content: @fa-var-mizuni;
}
.@{fa-css-prefix}-mobile:before {
  content: @fa-var-mobile;
}
.@{fa-css-prefix}-mobile-alt:before {
  content: @fa-var-mobile-alt;
}
.@{fa-css-prefix}-modx:before {
  content: @fa-var-modx;
}
.@{fa-css-prefix}-monero:before {
  content: @fa-var-monero;
}
.@{fa-css-prefix}-money-bill:before {
  content: @fa-var-money-bill;
}
.@{fa-css-prefix}-money-bill-alt:before {
  content: @fa-var-money-bill-alt;
}
.@{fa-css-prefix}-money-bill-wave:before {
  content: @fa-var-money-bill-wave;
}
.@{fa-css-prefix}-money-bill-wave-alt:before {
  content: @fa-var-money-bill-wave-alt;
}
.@{fa-css-prefix}-money-check:before {
  content: @fa-var-money-check;
}
.@{fa-css-prefix}-money-check-alt:before {
  content: @fa-var-money-check-alt;
}
.@{fa-css-prefix}-monument:before {
  content: @fa-var-monument;
}
.@{fa-css-prefix}-moon:before {
  content: @fa-var-moon;
}
.@{fa-css-prefix}-mortar-pestle:before {
  content: @fa-var-mortar-pestle;
}
.@{fa-css-prefix}-mosque:before {
  content: @fa-var-mosque;
}
.@{fa-css-prefix}-motorcycle:before {
  content: @fa-var-motorcycle;
}
.@{fa-css-prefix}-mountain:before {
  content: @fa-var-mountain;
}
.@{fa-css-prefix}-mouse:before {
  content: @fa-var-mouse;
}
.@{fa-css-prefix}-mouse-pointer:before {
  content: @fa-var-mouse-pointer;
}
.@{fa-css-prefix}-mug-hot:before {
  content: @fa-var-mug-hot;
}
.@{fa-css-prefix}-music:before {
  content: @fa-var-music;
}
.@{fa-css-prefix}-napster:before {
  content: @fa-var-napster;
}
.@{fa-css-prefix}-neos:before {
  content: @fa-var-neos;
}
.@{fa-css-prefix}-network-wired:before {
  content: @fa-var-network-wired;
}
.@{fa-css-prefix}-neuter:before {
  content: @fa-var-neuter;
}
.@{fa-css-prefix}-newspaper:before {
  content: @fa-var-newspaper;
}
.@{fa-css-prefix}-nimblr:before {
  content: @fa-var-nimblr;
}
.@{fa-css-prefix}-node:before {
  content: @fa-var-node;
}
.@{fa-css-prefix}-node-js:before {
  content: @fa-var-node-js;
}
.@{fa-css-prefix}-not-equal:before {
  content: @fa-var-not-equal;
}
.@{fa-css-prefix}-notes-medical:before {
  content: @fa-var-notes-medical;
}
.@{fa-css-prefix}-npm:before {
  content: @fa-var-npm;
}
.@{fa-css-prefix}-ns8:before {
  content: @fa-var-ns8;
}
.@{fa-css-prefix}-nutritionix:before {
  content: @fa-var-nutritionix;
}
.@{fa-css-prefix}-object-group:before {
  content: @fa-var-object-group;
}
.@{fa-css-prefix}-object-ungroup:before {
  content: @fa-var-object-ungroup;
}
.@{fa-css-prefix}-octopus-deploy:before {
  content: @fa-var-octopus-deploy;
}
.@{fa-css-prefix}-odnoklassniki:before {
  content: @fa-var-odnoklassniki;
}
.@{fa-css-prefix}-odnoklassniki-square:before {
  content: @fa-var-odnoklassniki-square;
}
.@{fa-css-prefix}-oil-can:before {
  content: @fa-var-oil-can;
}
.@{fa-css-prefix}-old-republic:before {
  content: @fa-var-old-republic;
}
.@{fa-css-prefix}-om:before {
  content: @fa-var-om;
}
.@{fa-css-prefix}-opencart:before {
  content: @fa-var-opencart;
}
.@{fa-css-prefix}-openid:before {
  content: @fa-var-openid;
}
.@{fa-css-prefix}-opera:before {
  content: @fa-var-opera;
}
.@{fa-css-prefix}-optin-monster:before {
  content: @fa-var-optin-monster;
}
.@{fa-css-prefix}-orcid:before {
  content: @fa-var-orcid;
}
.@{fa-css-prefix}-osi:before {
  content: @fa-var-osi;
}
.@{fa-css-prefix}-otter:before {
  content: @fa-var-otter;
}
.@{fa-css-prefix}-outdent:before {
  content: @fa-var-outdent;
}
.@{fa-css-prefix}-page4:before {
  content: @fa-var-page4;
}
.@{fa-css-prefix}-pagelines:before {
  content: @fa-var-pagelines;
}
.@{fa-css-prefix}-pager:before {
  content: @fa-var-pager;
}
.@{fa-css-prefix}-paint-brush:before {
  content: @fa-var-paint-brush;
}
.@{fa-css-prefix}-paint-roller:before {
  content: @fa-var-paint-roller;
}
.@{fa-css-prefix}-palette:before {
  content: @fa-var-palette;
}
.@{fa-css-prefix}-palfed:before {
  content: @fa-var-palfed;
}
.@{fa-css-prefix}-pallet:before {
  content: @fa-var-pallet;
}
.@{fa-css-prefix}-paper-plane:before {
  content: @fa-var-paper-plane;
}
.@{fa-css-prefix}-paperclip:before {
  content: @fa-var-paperclip;
}
.@{fa-css-prefix}-parachute-box:before {
  content: @fa-var-parachute-box;
}
.@{fa-css-prefix}-paragraph:before {
  content: @fa-var-paragraph;
}
.@{fa-css-prefix}-parking:before {
  content: @fa-var-parking;
}
.@{fa-css-prefix}-passport:before {
  content: @fa-var-passport;
}
.@{fa-css-prefix}-pastafarianism:before {
  content: @fa-var-pastafarianism;
}
.@{fa-css-prefix}-paste:before {
  content: @fa-var-paste;
}
.@{fa-css-prefix}-patreon:before {
  content: @fa-var-patreon;
}
.@{fa-css-prefix}-pause:before {
  content: @fa-var-pause;
}
.@{fa-css-prefix}-pause-circle:before {
  content: @fa-var-pause-circle;
}
.@{fa-css-prefix}-paw:before {
  content: @fa-var-paw;
}
.@{fa-css-prefix}-paypal:before {
  content: @fa-var-paypal;
}
.@{fa-css-prefix}-peace:before {
  content: @fa-var-peace;
}
.@{fa-css-prefix}-pen:before {
  content: @fa-var-pen;
}
.@{fa-css-prefix}-pen-alt:before {
  content: @fa-var-pen-alt;
}
.@{fa-css-prefix}-pen-fancy:before {
  content: @fa-var-pen-fancy;
}
.@{fa-css-prefix}-pen-nib:before {
  content: @fa-var-pen-nib;
}
.@{fa-css-prefix}-pen-square:before {
  content: @fa-var-pen-square;
}
.@{fa-css-prefix}-pencil-alt:before {
  content: @fa-var-pencil-alt;
}
.@{fa-css-prefix}-pencil-ruler:before {
  content: @fa-var-pencil-ruler;
}
.@{fa-css-prefix}-penny-arcade:before {
  content: @fa-var-penny-arcade;
}
.@{fa-css-prefix}-people-arrows:before {
  content: @fa-var-people-arrows;
}
.@{fa-css-prefix}-people-carry:before {
  content: @fa-var-people-carry;
}
.@{fa-css-prefix}-pepper-hot:before {
  content: @fa-var-pepper-hot;
}
.@{fa-css-prefix}-perbyte:before {
  content: @fa-var-perbyte;
}
.@{fa-css-prefix}-percent:before {
  content: @fa-var-percent;
}
.@{fa-css-prefix}-percentage:before {
  content: @fa-var-percentage;
}
.@{fa-css-prefix}-periscope:before {
  content: @fa-var-periscope;
}
.@{fa-css-prefix}-person-booth:before {
  content: @fa-var-person-booth;
}
.@{fa-css-prefix}-phabricator:before {
  content: @fa-var-phabricator;
}
.@{fa-css-prefix}-phoenix-framework:before {
  content: @fa-var-phoenix-framework;
}
.@{fa-css-prefix}-phoenix-squadron:before {
  content: @fa-var-phoenix-squadron;
}
.@{fa-css-prefix}-phone:before {
  content: @fa-var-phone;
}
.@{fa-css-prefix}-phone-alt:before {
  content: @fa-var-phone-alt;
}
.@{fa-css-prefix}-phone-slash:before {
  content: @fa-var-phone-slash;
}
.@{fa-css-prefix}-phone-square:before {
  content: @fa-var-phone-square;
}
.@{fa-css-prefix}-phone-square-alt:before {
  content: @fa-var-phone-square-alt;
}
.@{fa-css-prefix}-phone-volume:before {
  content: @fa-var-phone-volume;
}
.@{fa-css-prefix}-photo-video:before {
  content: @fa-var-photo-video;
}
.@{fa-css-prefix}-php:before {
  content: @fa-var-php;
}
.@{fa-css-prefix}-pied-piper:before {
  content: @fa-var-pied-piper;
}
.@{fa-css-prefix}-pied-piper-alt:before {
  content: @fa-var-pied-piper-alt;
}
.@{fa-css-prefix}-pied-piper-hat:before {
  content: @fa-var-pied-piper-hat;
}
.@{fa-css-prefix}-pied-piper-pp:before {
  content: @fa-var-pied-piper-pp;
}
.@{fa-css-prefix}-pied-piper-square:before {
  content: @fa-var-pied-piper-square;
}
.@{fa-css-prefix}-piggy-bank:before {
  content: @fa-var-piggy-bank;
}
.@{fa-css-prefix}-pills:before {
  content: @fa-var-pills;
}
.@{fa-css-prefix}-pinterest:before {
  content: @fa-var-pinterest;
}
.@{fa-css-prefix}-pinterest-p:before {
  content: @fa-var-pinterest-p;
}
.@{fa-css-prefix}-pinterest-square:before {
  content: @fa-var-pinterest-square;
}
.@{fa-css-prefix}-pizza-slice:before {
  content: @fa-var-pizza-slice;
}
.@{fa-css-prefix}-place-of-worship:before {
  content: @fa-var-place-of-worship;
}
.@{fa-css-prefix}-plane:before {
  content: @fa-var-plane;
}
.@{fa-css-prefix}-plane-arrival:before {
  content: @fa-var-plane-arrival;
}
.@{fa-css-prefix}-plane-departure:before {
  content: @fa-var-plane-departure;
}
.@{fa-css-prefix}-plane-slash:before {
  content: @fa-var-plane-slash;
}
.@{fa-css-prefix}-play:before {
  content: @fa-var-play;
}
.@{fa-css-prefix}-play-circle:before {
  content: @fa-var-play-circle;
}
.@{fa-css-prefix}-playstation:before {
  content: @fa-var-playstation;
}
.@{fa-css-prefix}-plug:before {
  content: @fa-var-plug;
}
.@{fa-css-prefix}-plus:before {
  content: @fa-var-plus;
}
.@{fa-css-prefix}-plus-circle:before {
  content: @fa-var-plus-circle;
}
.@{fa-css-prefix}-plus-square:before {
  content: @fa-var-plus-square;
}
.@{fa-css-prefix}-podcast:before {
  content: @fa-var-podcast;
}
.@{fa-css-prefix}-poll:before {
  content: @fa-var-poll;
}
.@{fa-css-prefix}-poll-h:before {
  content: @fa-var-poll-h;
}
.@{fa-css-prefix}-poo:before {
  content: @fa-var-poo;
}
.@{fa-css-prefix}-poo-storm:before {
  content: @fa-var-poo-storm;
}
.@{fa-css-prefix}-poop:before {
  content: @fa-var-poop;
}
.@{fa-css-prefix}-portrait:before {
  content: @fa-var-portrait;
}
.@{fa-css-prefix}-pound-sign:before {
  content: @fa-var-pound-sign;
}
.@{fa-css-prefix}-power-off:before {
  content: @fa-var-power-off;
}
.@{fa-css-prefix}-pray:before {
  content: @fa-var-pray;
}
.@{fa-css-prefix}-praying-hands:before {
  content: @fa-var-praying-hands;
}
.@{fa-css-prefix}-prescription:before {
  content: @fa-var-prescription;
}
.@{fa-css-prefix}-prescription-bottle:before {
  content: @fa-var-prescription-bottle;
}
.@{fa-css-prefix}-prescription-bottle-alt:before {
  content: @fa-var-prescription-bottle-alt;
}
.@{fa-css-prefix}-print:before {
  content: @fa-var-print;
}
.@{fa-css-prefix}-procedures:before {
  content: @fa-var-procedures;
}
.@{fa-css-prefix}-product-hunt:before {
  content: @fa-var-product-hunt;
}
.@{fa-css-prefix}-project-diagram:before {
  content: @fa-var-project-diagram;
}
.@{fa-css-prefix}-pump-medical:before {
  content: @fa-var-pump-medical;
}
.@{fa-css-prefix}-pump-soap:before {
  content: @fa-var-pump-soap;
}
.@{fa-css-prefix}-pushed:before {
  content: @fa-var-pushed;
}
.@{fa-css-prefix}-puzzle-piece:before {
  content: @fa-var-puzzle-piece;
}
.@{fa-css-prefix}-python:before {
  content: @fa-var-python;
}
.@{fa-css-prefix}-qq:before {
  content: @fa-var-qq;
}
.@{fa-css-prefix}-qrcode:before {
  content: @fa-var-qrcode;
}
.@{fa-css-prefix}-question:before {
  content: @fa-var-question;
}
.@{fa-css-prefix}-question-circle:before {
  content: @fa-var-question-circle;
}
.@{fa-css-prefix}-quidditch:before {
  content: @fa-var-quidditch;
}
.@{fa-css-prefix}-quinscape:before {
  content: @fa-var-quinscape;
}
.@{fa-css-prefix}-quora:before {
  content: @fa-var-quora;
}
.@{fa-css-prefix}-quote-left:before {
  content: @fa-var-quote-left;
}
.@{fa-css-prefix}-quote-right:before {
  content: @fa-var-quote-right;
}
.@{fa-css-prefix}-quran:before {
  content: @fa-var-quran;
}
.@{fa-css-prefix}-r-project:before {
  content: @fa-var-r-project;
}
.@{fa-css-prefix}-radiation:before {
  content: @fa-var-radiation;
}
.@{fa-css-prefix}-radiation-alt:before {
  content: @fa-var-radiation-alt;
}
.@{fa-css-prefix}-rainbow:before {
  content: @fa-var-rainbow;
}
.@{fa-css-prefix}-random:before {
  content: @fa-var-random;
}
.@{fa-css-prefix}-raspberry-pi:before {
  content: @fa-var-raspberry-pi;
}
.@{fa-css-prefix}-ravelry:before {
  content: @fa-var-ravelry;
}
.@{fa-css-prefix}-react:before {
  content: @fa-var-react;
}
.@{fa-css-prefix}-reacteurope:before {
  content: @fa-var-reacteurope;
}
.@{fa-css-prefix}-readme:before {
  content: @fa-var-readme;
}
.@{fa-css-prefix}-rebel:before {
  content: @fa-var-rebel;
}
.@{fa-css-prefix}-receipt:before {
  content: @fa-var-receipt;
}
.@{fa-css-prefix}-record-vinyl:before {
  content: @fa-var-record-vinyl;
}
.@{fa-css-prefix}-recycle:before {
  content: @fa-var-recycle;
}
.@{fa-css-prefix}-red-river:before {
  content: @fa-var-red-river;
}
.@{fa-css-prefix}-reddit:before {
  content: @fa-var-reddit;
}
.@{fa-css-prefix}-reddit-alien:before {
  content: @fa-var-reddit-alien;
}
.@{fa-css-prefix}-reddit-square:before {
  content: @fa-var-reddit-square;
}
.@{fa-css-prefix}-redhat:before {
  content: @fa-var-redhat;
}
.@{fa-css-prefix}-redo:before {
  content: @fa-var-redo;
}
.@{fa-css-prefix}-redo-alt:before {
  content: @fa-var-redo-alt;
}
.@{fa-css-prefix}-registered:before {
  content: @fa-var-registered;
}
.@{fa-css-prefix}-remove-format:before {
  content: @fa-var-remove-format;
}
.@{fa-css-prefix}-renren:before {
  content: @fa-var-renren;
}
.@{fa-css-prefix}-reply:before {
  content: @fa-var-reply;
}
.@{fa-css-prefix}-reply-all:before {
  content: @fa-var-reply-all;
}
.@{fa-css-prefix}-replyd:before {
  content: @fa-var-replyd;
}
.@{fa-css-prefix}-republican:before {
  content: @fa-var-republican;
}
.@{fa-css-prefix}-researchgate:before {
  content: @fa-var-researchgate;
}
.@{fa-css-prefix}-resolving:before {
  content: @fa-var-resolving;
}
.@{fa-css-prefix}-restroom:before {
  content: @fa-var-restroom;
}
.@{fa-css-prefix}-retweet:before {
  content: @fa-var-retweet;
}
.@{fa-css-prefix}-rev:before {
  content: @fa-var-rev;
}
.@{fa-css-prefix}-ribbon:before {
  content: @fa-var-ribbon;
}
.@{fa-css-prefix}-ring:before {
  content: @fa-var-ring;
}
.@{fa-css-prefix}-road:before {
  content: @fa-var-road;
}
.@{fa-css-prefix}-robot:before {
  content: @fa-var-robot;
}
.@{fa-css-prefix}-rocket:before {
  content: @fa-var-rocket;
}
.@{fa-css-prefix}-rocketchat:before {
  content: @fa-var-rocketchat;
}
.@{fa-css-prefix}-rockrms:before {
  content: @fa-var-rockrms;
}
.@{fa-css-prefix}-route:before {
  content: @fa-var-route;
}
.@{fa-css-prefix}-rss:before {
  content: @fa-var-rss;
}
.@{fa-css-prefix}-rss-square:before {
  content: @fa-var-rss-square;
}
.@{fa-css-prefix}-ruble-sign:before {
  content: @fa-var-ruble-sign;
}
.@{fa-css-prefix}-ruler:before {
  content: @fa-var-ruler;
}
.@{fa-css-prefix}-ruler-combined:before {
  content: @fa-var-ruler-combined;
}
.@{fa-css-prefix}-ruler-horizontal:before {
  content: @fa-var-ruler-horizontal;
}
.@{fa-css-prefix}-ruler-vertical:before {
  content: @fa-var-ruler-vertical;
}
.@{fa-css-prefix}-running:before {
  content: @fa-var-running;
}
.@{fa-css-prefix}-rupee-sign:before {
  content: @fa-var-rupee-sign;
}
.@{fa-css-prefix}-rust:before {
  content: @fa-var-rust;
}
.@{fa-css-prefix}-sad-cry:before {
  content: @fa-var-sad-cry;
}
.@{fa-css-prefix}-sad-tear:before {
  content: @fa-var-sad-tear;
}
.@{fa-css-prefix}-safari:before {
  content: @fa-var-safari;
}
.@{fa-css-prefix}-salesforce:before {
  content: @fa-var-salesforce;
}
.@{fa-css-prefix}-sass:before {
  content: @fa-var-sass;
}
.@{fa-css-prefix}-satellite:before {
  content: @fa-var-satellite;
}
.@{fa-css-prefix}-satellite-dish:before {
  content: @fa-var-satellite-dish;
}
.@{fa-css-prefix}-save:before {
  content: @fa-var-save;
}
.@{fa-css-prefix}-schlix:before {
  content: @fa-var-schlix;
}
.@{fa-css-prefix}-school:before {
  content: @fa-var-school;
}
.@{fa-css-prefix}-screwdriver:before {
  content: @fa-var-screwdriver;
}
.@{fa-css-prefix}-scribd:before {
  content: @fa-var-scribd;
}
.@{fa-css-prefix}-scroll:before {
  content: @fa-var-scroll;
}
.@{fa-css-prefix}-sd-card:before {
  content: @fa-var-sd-card;
}
.@{fa-css-prefix}-search:before {
  content: @fa-var-search;
}
.@{fa-css-prefix}-search-dollar:before {
  content: @fa-var-search-dollar;
}
.@{fa-css-prefix}-search-location:before {
  content: @fa-var-search-location;
}
.@{fa-css-prefix}-search-minus:before {
  content: @fa-var-search-minus;
}
.@{fa-css-prefix}-search-plus:before {
  content: @fa-var-search-plus;
}
.@{fa-css-prefix}-searchengin:before {
  content: @fa-var-searchengin;
}
.@{fa-css-prefix}-seedling:before {
  content: @fa-var-seedling;
}
.@{fa-css-prefix}-sellcast:before {
  content: @fa-var-sellcast;
}
.@{fa-css-prefix}-sellsy:before {
  content: @fa-var-sellsy;
}
.@{fa-css-prefix}-server:before {
  content: @fa-var-server;
}
.@{fa-css-prefix}-servicestack:before {
  content: @fa-var-servicestack;
}
.@{fa-css-prefix}-shapes:before {
  content: @fa-var-shapes;
}
.@{fa-css-prefix}-share:before {
  content: @fa-var-share;
}
.@{fa-css-prefix}-share-alt:before {
  content: @fa-var-share-alt;
}
.@{fa-css-prefix}-share-alt-square:before {
  content: @fa-var-share-alt-square;
}
.@{fa-css-prefix}-share-square:before {
  content: @fa-var-share-square;
}
.@{fa-css-prefix}-shekel-sign:before {
  content: @fa-var-shekel-sign;
}
.@{fa-css-prefix}-shield-alt:before {
  content: @fa-var-shield-alt;
}
.@{fa-css-prefix}-shield-virus:before {
  content: @fa-var-shield-virus;
}
.@{fa-css-prefix}-ship:before {
  content: @fa-var-ship;
}
.@{fa-css-prefix}-shipping-fast:before {
  content: @fa-var-shipping-fast;
}
.@{fa-css-prefix}-shirtsinbulk:before {
  content: @fa-var-shirtsinbulk;
}
.@{fa-css-prefix}-shoe-prints:before {
  content: @fa-var-shoe-prints;
}
.@{fa-css-prefix}-shopify:before {
  content: @fa-var-shopify;
}
.@{fa-css-prefix}-shopping-bag:before {
  content: @fa-var-shopping-bag;
}
.@{fa-css-prefix}-shopping-basket:before {
  content: @fa-var-shopping-basket;
}
.@{fa-css-prefix}-shopping-cart:before {
  content: @fa-var-shopping-cart;
}
.@{fa-css-prefix}-shopware:before {
  content: @fa-var-shopware;
}
.@{fa-css-prefix}-shower:before {
  content: @fa-var-shower;
}
.@{fa-css-prefix}-shuttle-van:before {
  content: @fa-var-shuttle-van;
}
.@{fa-css-prefix}-sign:before {
  content: @fa-var-sign;
}
.@{fa-css-prefix}-sign-in-alt:before {
  content: @fa-var-sign-in-alt;
}
.@{fa-css-prefix}-sign-language:before {
  content: @fa-var-sign-language;
}
.@{fa-css-prefix}-sign-out-alt:before {
  content: @fa-var-sign-out-alt;
}
.@{fa-css-prefix}-signal:before {
  content: @fa-var-signal;
}
.@{fa-css-prefix}-signature:before {
  content: @fa-var-signature;
}
.@{fa-css-prefix}-sim-card:before {
  content: @fa-var-sim-card;
}
.@{fa-css-prefix}-simplybuilt:before {
  content: @fa-var-simplybuilt;
}
.@{fa-css-prefix}-sink:before {
  content: @fa-var-sink;
}
.@{fa-css-prefix}-sistrix:before {
  content: @fa-var-sistrix;
}
.@{fa-css-prefix}-sitemap:before {
  content: @fa-var-sitemap;
}
.@{fa-css-prefix}-sith:before {
  content: @fa-var-sith;
}
.@{fa-css-prefix}-skating:before {
  content: @fa-var-skating;
}
.@{fa-css-prefix}-sketch:before {
  content: @fa-var-sketch;
}
.@{fa-css-prefix}-skiing:before {
  content: @fa-var-skiing;
}
.@{fa-css-prefix}-skiing-nordic:before {
  content: @fa-var-skiing-nordic;
}
.@{fa-css-prefix}-skull:before {
  content: @fa-var-skull;
}
.@{fa-css-prefix}-skull-crossbones:before {
  content: @fa-var-skull-crossbones;
}
.@{fa-css-prefix}-skyatlas:before {
  content: @fa-var-skyatlas;
}
.@{fa-css-prefix}-skype:before {
  content: @fa-var-skype;
}
.@{fa-css-prefix}-slack:before {
  content: @fa-var-slack;
}
.@{fa-css-prefix}-slack-hash:before {
  content: @fa-var-slack-hash;
}
.@{fa-css-prefix}-slash:before {
  content: @fa-var-slash;
}
.@{fa-css-prefix}-sleigh:before {
  content: @fa-var-sleigh;
}
.@{fa-css-prefix}-sliders-h:before {
  content: @fa-var-sliders-h;
}
.@{fa-css-prefix}-slideshare:before {
  content: @fa-var-slideshare;
}
.@{fa-css-prefix}-smile:before {
  content: @fa-var-smile;
}
.@{fa-css-prefix}-smile-beam:before {
  content: @fa-var-smile-beam;
}
.@{fa-css-prefix}-smile-wink:before {
  content: @fa-var-smile-wink;
}
.@{fa-css-prefix}-smog:before {
  content: @fa-var-smog;
}
.@{fa-css-prefix}-smoking:before {
  content: @fa-var-smoking;
}
.@{fa-css-prefix}-smoking-ban:before {
  content: @fa-var-smoking-ban;
}
.@{fa-css-prefix}-sms:before {
  content: @fa-var-sms;
}
.@{fa-css-prefix}-snapchat:before {
  content: @fa-var-snapchat;
}
.@{fa-css-prefix}-snapchat-ghost:before {
  content: @fa-var-snapchat-ghost;
}
.@{fa-css-prefix}-snapchat-square:before {
  content: @fa-var-snapchat-square;
}
.@{fa-css-prefix}-snowboarding:before {
  content: @fa-var-snowboarding;
}
.@{fa-css-prefix}-snowflake:before {
  content: @fa-var-snowflake;
}
.@{fa-css-prefix}-snowman:before {
  content: @fa-var-snowman;
}
.@{fa-css-prefix}-snowplow:before {
  content: @fa-var-snowplow;
}
.@{fa-css-prefix}-soap:before {
  content: @fa-var-soap;
}
.@{fa-css-prefix}-socks:before {
  content: @fa-var-socks;
}
.@{fa-css-prefix}-solar-panel:before {
  content: @fa-var-solar-panel;
}
.@{fa-css-prefix}-sort:before {
  content: @fa-var-sort;
}
.@{fa-css-prefix}-sort-alpha-down:before {
  content: @fa-var-sort-alpha-down;
}
.@{fa-css-prefix}-sort-alpha-down-alt:before {
  content: @fa-var-sort-alpha-down-alt;
}
.@{fa-css-prefix}-sort-alpha-up:before {
  content: @fa-var-sort-alpha-up;
}
.@{fa-css-prefix}-sort-alpha-up-alt:before {
  content: @fa-var-sort-alpha-up-alt;
}
.@{fa-css-prefix}-sort-amount-down:before {
  content: @fa-var-sort-amount-down;
}
.@{fa-css-prefix}-sort-amount-down-alt:before {
  content: @fa-var-sort-amount-down-alt;
}
.@{fa-css-prefix}-sort-amount-up:before {
  content: @fa-var-sort-amount-up;
}
.@{fa-css-prefix}-sort-amount-up-alt:before {
  content: @fa-var-sort-amount-up-alt;
}
.@{fa-css-prefix}-sort-down:before {
  content: @fa-var-sort-down;
}
.@{fa-css-prefix}-sort-numeric-down:before {
  content: @fa-var-sort-numeric-down;
}
.@{fa-css-prefix}-sort-numeric-down-alt:before {
  content: @fa-var-sort-numeric-down-alt;
}
.@{fa-css-prefix}-sort-numeric-up:before {
  content: @fa-var-sort-numeric-up;
}
.@{fa-css-prefix}-sort-numeric-up-alt:before {
  content: @fa-var-sort-numeric-up-alt;
}
.@{fa-css-prefix}-sort-up:before {
  content: @fa-var-sort-up;
}
.@{fa-css-prefix}-soundcloud:before {
  content: @fa-var-soundcloud;
}
.@{fa-css-prefix}-sourcetree:before {
  content: @fa-var-sourcetree;
}
.@{fa-css-prefix}-spa:before {
  content: @fa-var-spa;
}
.@{fa-css-prefix}-space-shuttle:before {
  content: @fa-var-space-shuttle;
}
.@{fa-css-prefix}-speakap:before {
  content: @fa-var-speakap;
}
.@{fa-css-prefix}-speaker-deck:before {
  content: @fa-var-speaker-deck;
}
.@{fa-css-prefix}-spell-check:before {
  content: @fa-var-spell-check;
}
.@{fa-css-prefix}-spider:before {
  content: @fa-var-spider;
}
.@{fa-css-prefix}-spinner:before {
  content: @fa-var-spinner;
}
.@{fa-css-prefix}-splotch:before {
  content: @fa-var-splotch;
}
.@{fa-css-prefix}-spotify:before {
  content: @fa-var-spotify;
}
.@{fa-css-prefix}-spray-can:before {
  content: @fa-var-spray-can;
}
.@{fa-css-prefix}-square:before {
  content: @fa-var-square;
}
.@{fa-css-prefix}-square-full:before {
  content: @fa-var-square-full;
}
.@{fa-css-prefix}-square-root-alt:before {
  content: @fa-var-square-root-alt;
}
.@{fa-css-prefix}-squarespace:before {
  content: @fa-var-squarespace;
}
.@{fa-css-prefix}-stack-exchange:before {
  content: @fa-var-stack-exchange;
}
.@{fa-css-prefix}-stack-overflow:before {
  content: @fa-var-stack-overflow;
}
.@{fa-css-prefix}-stackpath:before {
  content: @fa-var-stackpath;
}
.@{fa-css-prefix}-stamp:before {
  content: @fa-var-stamp;
}
.@{fa-css-prefix}-star:before {
  content: @fa-var-star;
}
.@{fa-css-prefix}-star-and-crescent:before {
  content: @fa-var-star-and-crescent;
}
.@{fa-css-prefix}-star-half:before {
  content: @fa-var-star-half;
}
.@{fa-css-prefix}-star-half-alt:before {
  content: @fa-var-star-half-alt;
}
.@{fa-css-prefix}-star-of-david:before {
  content: @fa-var-star-of-david;
}
.@{fa-css-prefix}-star-of-life:before {
  content: @fa-var-star-of-life;
}
.@{fa-css-prefix}-staylinked:before {
  content: @fa-var-staylinked;
}
.@{fa-css-prefix}-steam:before {
  content: @fa-var-steam;
}
.@{fa-css-prefix}-steam-square:before {
  content: @fa-var-steam-square;
}
.@{fa-css-prefix}-steam-symbol:before {
  content: @fa-var-steam-symbol;
}
.@{fa-css-prefix}-step-backward:before {
  content: @fa-var-step-backward;
}
.@{fa-css-prefix}-step-forward:before {
  content: @fa-var-step-forward;
}
.@{fa-css-prefix}-stethoscope:before {
  content: @fa-var-stethoscope;
}
.@{fa-css-prefix}-sticker-mule:before {
  content: @fa-var-sticker-mule;
}
.@{fa-css-prefix}-sticky-note:before {
  content: @fa-var-sticky-note;
}
.@{fa-css-prefix}-stop:before {
  content: @fa-var-stop;
}
.@{fa-css-prefix}-stop-circle:before {
  content: @fa-var-stop-circle;
}
.@{fa-css-prefix}-stopwatch:before {
  content: @fa-var-stopwatch;
}
.@{fa-css-prefix}-stopwatch-20:before {
  content: @fa-var-stopwatch-20;
}
.@{fa-css-prefix}-store:before {
  content: @fa-var-store;
}
.@{fa-css-prefix}-store-alt:before {
  content: @fa-var-store-alt;
}
.@{fa-css-prefix}-store-alt-slash:before {
  content: @fa-var-store-alt-slash;
}
.@{fa-css-prefix}-store-slash:before {
  content: @fa-var-store-slash;
}
.@{fa-css-prefix}-strava:before {
  content: @fa-var-strava;
}
.@{fa-css-prefix}-stream:before {
  content: @fa-var-stream;
}
.@{fa-css-prefix}-street-view:before {
  content: @fa-var-street-view;
}
.@{fa-css-prefix}-strikethrough:before {
  content: @fa-var-strikethrough;
}
.@{fa-css-prefix}-stripe:before {
  content: @fa-var-stripe;
}
.@{fa-css-prefix}-stripe-s:before {
  content: @fa-var-stripe-s;
}
.@{fa-css-prefix}-stroopwafel:before {
  content: @fa-var-stroopwafel;
}
.@{fa-css-prefix}-studiovinari:before {
  content: @fa-var-studiovinari;
}
.@{fa-css-prefix}-stumbleupon:before {
  content: @fa-var-stumbleupon;
}
.@{fa-css-prefix}-stumbleupon-circle:before {
  content: @fa-var-stumbleupon-circle;
}
.@{fa-css-prefix}-subscript:before {
  content: @fa-var-subscript;
}
.@{fa-css-prefix}-subway:before {
  content: @fa-var-subway;
}
.@{fa-css-prefix}-suitcase:before {
  content: @fa-var-suitcase;
}
.@{fa-css-prefix}-suitcase-rolling:before {
  content: @fa-var-suitcase-rolling;
}
.@{fa-css-prefix}-sun:before {
  content: @fa-var-sun;
}
.@{fa-css-prefix}-superpowers:before {
  content: @fa-var-superpowers;
}
.@{fa-css-prefix}-superscript:before {
  content: @fa-var-superscript;
}
.@{fa-css-prefix}-supple:before {
  content: @fa-var-supple;
}
.@{fa-css-prefix}-surprise:before {
  content: @fa-var-surprise;
}
.@{fa-css-prefix}-suse:before {
  content: @fa-var-suse;
}
.@{fa-css-prefix}-swatchbook:before {
  content: @fa-var-swatchbook;
}
.@{fa-css-prefix}-swift:before {
  content: @fa-var-swift;
}
.@{fa-css-prefix}-swimmer:before {
  content: @fa-var-swimmer;
}
.@{fa-css-prefix}-swimming-pool:before {
  content: @fa-var-swimming-pool;
}
.@{fa-css-prefix}-symfony:before {
  content: @fa-var-symfony;
}
.@{fa-css-prefix}-synagogue:before {
  content: @fa-var-synagogue;
}
.@{fa-css-prefix}-sync:before {
  content: @fa-var-sync;
}
.@{fa-css-prefix}-sync-alt:before {
  content: @fa-var-sync-alt;
}
.@{fa-css-prefix}-syringe:before {
  content: @fa-var-syringe;
}
.@{fa-css-prefix}-table:before {
  content: @fa-var-table;
}
.@{fa-css-prefix}-table-tennis:before {
  content: @fa-var-table-tennis;
}
.@{fa-css-prefix}-tablet:before {
  content: @fa-var-tablet;
}
.@{fa-css-prefix}-tablet-alt:before {
  content: @fa-var-tablet-alt;
}
.@{fa-css-prefix}-tablets:before {
  content: @fa-var-tablets;
}
.@{fa-css-prefix}-tachometer-alt:before {
  content: @fa-var-tachometer-alt;
}
.@{fa-css-prefix}-tag:before {
  content: @fa-var-tag;
}
.@{fa-css-prefix}-tags:before {
  content: @fa-var-tags;
}
.@{fa-css-prefix}-tape:before {
  content: @fa-var-tape;
}
.@{fa-css-prefix}-tasks:before {
  content: @fa-var-tasks;
}
.@{fa-css-prefix}-taxi:before {
  content: @fa-var-taxi;
}
.@{fa-css-prefix}-teamspeak:before {
  content: @fa-var-teamspeak;
}
.@{fa-css-prefix}-teeth:before {
  content: @fa-var-teeth;
}
.@{fa-css-prefix}-teeth-open:before {
  content: @fa-var-teeth-open;
}
.@{fa-css-prefix}-telegram:before {
  content: @fa-var-telegram;
}
.@{fa-css-prefix}-telegram-plane:before {
  content: @fa-var-telegram-plane;
}
.@{fa-css-prefix}-temperature-high:before {
  content: @fa-var-temperature-high;
}
.@{fa-css-prefix}-temperature-low:before {
  content: @fa-var-temperature-low;
}
.@{fa-css-prefix}-tencent-weibo:before {
  content: @fa-var-tencent-weibo;
}
.@{fa-css-prefix}-tenge:before {
  content: @fa-var-tenge;
}
.@{fa-css-prefix}-terminal:before {
  content: @fa-var-terminal;
}
.@{fa-css-prefix}-text-height:before {
  content: @fa-var-text-height;
}
.@{fa-css-prefix}-text-width:before {
  content: @fa-var-text-width;
}
.@{fa-css-prefix}-th:before {
  content: @fa-var-th;
}
.@{fa-css-prefix}-th-large:before {
  content: @fa-var-th-large;
}
.@{fa-css-prefix}-th-list:before {
  content: @fa-var-th-list;
}
.@{fa-css-prefix}-the-red-yeti:before {
  content: @fa-var-the-red-yeti;
}
.@{fa-css-prefix}-theater-masks:before {
  content: @fa-var-theater-masks;
}
.@{fa-css-prefix}-themeco:before {
  content: @fa-var-themeco;
}
.@{fa-css-prefix}-themeisle:before {
  content: @fa-var-themeisle;
}
.@{fa-css-prefix}-thermometer:before {
  content: @fa-var-thermometer;
}
.@{fa-css-prefix}-thermometer-empty:before {
  content: @fa-var-thermometer-empty;
}
.@{fa-css-prefix}-thermometer-full:before {
  content: @fa-var-thermometer-full;
}
.@{fa-css-prefix}-thermometer-half:before {
  content: @fa-var-thermometer-half;
}
.@{fa-css-prefix}-thermometer-quarter:before {
  content: @fa-var-thermometer-quarter;
}
.@{fa-css-prefix}-thermometer-three-quarters:before {
  content: @fa-var-thermometer-three-quarters;
}
.@{fa-css-prefix}-think-peaks:before {
  content: @fa-var-think-peaks;
}
.@{fa-css-prefix}-thumbs-down:before {
  content: @fa-var-thumbs-down;
}
.@{fa-css-prefix}-thumbs-up:before {
  content: @fa-var-thumbs-up;
}
.@{fa-css-prefix}-thumbtack:before {
  content: @fa-var-thumbtack;
}
.@{fa-css-prefix}-ticket-alt:before {
  content: @fa-var-ticket-alt;
}
.@{fa-css-prefix}-tiktok:before {
  content: @fa-var-tiktok;
}
.@{fa-css-prefix}-times:before {
  content: @fa-var-times;
}
.@{fa-css-prefix}-times-circle:before {
  content: @fa-var-times-circle;
}
.@{fa-css-prefix}-tint:before {
  content: @fa-var-tint;
}
.@{fa-css-prefix}-tint-slash:before {
  content: @fa-var-tint-slash;
}
.@{fa-css-prefix}-tired:before {
  content: @fa-var-tired;
}
.@{fa-css-prefix}-toggle-off:before {
  content: @fa-var-toggle-off;
}
.@{fa-css-prefix}-toggle-on:before {
  content: @fa-var-toggle-on;
}
.@{fa-css-prefix}-toilet:before {
  content: @fa-var-toilet;
}
.@{fa-css-prefix}-toilet-paper:before {
  content: @fa-var-toilet-paper;
}
.@{fa-css-prefix}-toilet-paper-slash:before {
  content: @fa-var-toilet-paper-slash;
}
.@{fa-css-prefix}-toolbox:before {
  content: @fa-var-toolbox;
}
.@{fa-css-prefix}-tools:before {
  content: @fa-var-tools;
}
.@{fa-css-prefix}-tooth:before {
  content: @fa-var-tooth;
}
.@{fa-css-prefix}-torah:before {
  content: @fa-var-torah;
}
.@{fa-css-prefix}-torii-gate:before {
  content: @fa-var-torii-gate;
}
.@{fa-css-prefix}-tractor:before {
  content: @fa-var-tractor;
}
.@{fa-css-prefix}-trade-federation:before {
  content: @fa-var-trade-federation;
}
.@{fa-css-prefix}-trademark:before {
  content: @fa-var-trademark;
}
.@{fa-css-prefix}-traffic-light:before {
  content: @fa-var-traffic-light;
}
.@{fa-css-prefix}-trailer:before {
  content: @fa-var-trailer;
}
.@{fa-css-prefix}-train:before {
  content: @fa-var-train;
}
.@{fa-css-prefix}-tram:before {
  content: @fa-var-tram;
}
.@{fa-css-prefix}-transgender:before {
  content: @fa-var-transgender;
}
.@{fa-css-prefix}-transgender-alt:before {
  content: @fa-var-transgender-alt;
}
.@{fa-css-prefix}-trash:before {
  content: @fa-var-trash;
}
.@{fa-css-prefix}-trash-alt:before {
  content: @fa-var-trash-alt;
}
.@{fa-css-prefix}-trash-restore:before {
  content: @fa-var-trash-restore;
}
.@{fa-css-prefix}-trash-restore-alt:before {
  content: @fa-var-trash-restore-alt;
}
.@{fa-css-prefix}-tree:before {
  content: @fa-var-tree;
}
.@{fa-css-prefix}-trello:before {
  content: @fa-var-trello;
}
.@{fa-css-prefix}-tripadvisor:before {
  content: @fa-var-tripadvisor;
}
.@{fa-css-prefix}-trophy:before {
  content: @fa-var-trophy;
}
.@{fa-css-prefix}-truck:before {
  content: @fa-var-truck;
}
.@{fa-css-prefix}-truck-loading:before {
  content: @fa-var-truck-loading;
}
.@{fa-css-prefix}-truck-monster:before {
  content: @fa-var-truck-monster;
}
.@{fa-css-prefix}-truck-moving:before {
  content: @fa-var-truck-moving;
}
.@{fa-css-prefix}-truck-pickup:before {
  content: @fa-var-truck-pickup;
}
.@{fa-css-prefix}-tshirt:before {
  content: @fa-var-tshirt;
}
.@{fa-css-prefix}-tty:before {
  content: @fa-var-tty;
}
.@{fa-css-prefix}-tumblr:before {
  content: @fa-var-tumblr;
}
.@{fa-css-prefix}-tumblr-square:before {
  content: @fa-var-tumblr-square;
}
.@{fa-css-prefix}-tv:before {
  content: @fa-var-tv;
}
.@{fa-css-prefix}-twitch:before {
  content: @fa-var-twitch;
}
.@{fa-css-prefix}-twitter:before {
  content: @fa-var-twitter;
}
.@{fa-css-prefix}-twitter-square:before {
  content: @fa-var-twitter-square;
}
.@{fa-css-prefix}-typo3:before {
  content: @fa-var-typo3;
}
.@{fa-css-prefix}-uber:before {
  content: @fa-var-uber;
}
.@{fa-css-prefix}-ubuntu:before {
  content: @fa-var-ubuntu;
}
.@{fa-css-prefix}-uikit:before {
  content: @fa-var-uikit;
}
.@{fa-css-prefix}-umbraco:before {
  content: @fa-var-umbraco;
}
.@{fa-css-prefix}-umbrella:before {
  content: @fa-var-umbrella;
}
.@{fa-css-prefix}-umbrella-beach:before {
  content: @fa-var-umbrella-beach;
}
.@{fa-css-prefix}-uncharted:before {
  content: @fa-var-uncharted;
}
.@{fa-css-prefix}-underline:before {
  content: @fa-var-underline;
}
.@{fa-css-prefix}-undo:before {
  content: @fa-var-undo;
}
.@{fa-css-prefix}-undo-alt:before {
  content: @fa-var-undo-alt;
}
.@{fa-css-prefix}-uniregistry:before {
  content: @fa-var-uniregistry;
}
.@{fa-css-prefix}-unity:before {
  content: @fa-var-unity;
}
.@{fa-css-prefix}-universal-access:before {
  content: @fa-var-universal-access;
}
.@{fa-css-prefix}-university:before {
  content: @fa-var-university;
}
.@{fa-css-prefix}-unlink:before {
  content: @fa-var-unlink;
}
.@{fa-css-prefix}-unlock:before {
  content: @fa-var-unlock;
}
.@{fa-css-prefix}-unlock-alt:before {
  content: @fa-var-unlock-alt;
}
.@{fa-css-prefix}-unsplash:before {
  content: @fa-var-unsplash;
}
.@{fa-css-prefix}-untappd:before {
  content: @fa-var-untappd;
}
.@{fa-css-prefix}-upload:before {
  content: @fa-var-upload;
}
.@{fa-css-prefix}-ups:before {
  content: @fa-var-ups;
}
.@{fa-css-prefix}-usb:before {
  content: @fa-var-usb;
}
.@{fa-css-prefix}-user:before {
  content: @fa-var-user;
}
.@{fa-css-prefix}-user-alt:before {
  content: @fa-var-user-alt;
}
.@{fa-css-prefix}-user-alt-slash:before {
  content: @fa-var-user-alt-slash;
}
.@{fa-css-prefix}-user-astronaut:before {
  content: @fa-var-user-astronaut;
}
.@{fa-css-prefix}-user-check:before {
  content: @fa-var-user-check;
}
.@{fa-css-prefix}-user-circle:before {
  content: @fa-var-user-circle;
}
.@{fa-css-prefix}-user-clock:before {
  content: @fa-var-user-clock;
}
.@{fa-css-prefix}-user-cog:before {
  content: @fa-var-user-cog;
}
.@{fa-css-prefix}-user-edit:before {
  content: @fa-var-user-edit;
}
.@{fa-css-prefix}-user-friends:before {
  content: @fa-var-user-friends;
}
.@{fa-css-prefix}-user-graduate:before {
  content: @fa-var-user-graduate;
}
.@{fa-css-prefix}-user-injured:before {
  content: @fa-var-user-injured;
}
.@{fa-css-prefix}-user-lock:before {
  content: @fa-var-user-lock;
}
.@{fa-css-prefix}-user-md:before {
  content: @fa-var-user-md;
}
.@{fa-css-prefix}-user-minus:before {
  content: @fa-var-user-minus;
}
.@{fa-css-prefix}-user-ninja:before {
  content: @fa-var-user-ninja;
}
.@{fa-css-prefix}-user-nurse:before {
  content: @fa-var-user-nurse;
}
.@{fa-css-prefix}-user-plus:before {
  content: @fa-var-user-plus;
}
.@{fa-css-prefix}-user-secret:before {
  content: @fa-var-user-secret;
}
.@{fa-css-prefix}-user-shield:before {
  content: @fa-var-user-shield;
}
.@{fa-css-prefix}-user-slash:before {
  content: @fa-var-user-slash;
}
.@{fa-css-prefix}-user-tag:before {
  content: @fa-var-user-tag;
}
.@{fa-css-prefix}-user-tie:before {
  content: @fa-var-user-tie;
}
.@{fa-css-prefix}-user-times:before {
  content: @fa-var-user-times;
}
.@{fa-css-prefix}-users:before {
  content: @fa-var-users;
}
.@{fa-css-prefix}-users-cog:before {
  content: @fa-var-users-cog;
}
.@{fa-css-prefix}-users-slash:before {
  content: @fa-var-users-slash;
}
.@{fa-css-prefix}-usps:before {
  content: @fa-var-usps;
}
.@{fa-css-prefix}-ussunnah:before {
  content: @fa-var-ussunnah;
}
.@{fa-css-prefix}-utensil-spoon:before {
  content: @fa-var-utensil-spoon;
}
.@{fa-css-prefix}-utensils:before {
  content: @fa-var-utensils;
}
.@{fa-css-prefix}-vaadin:before {
  content: @fa-var-vaadin;
}
.@{fa-css-prefix}-vector-square:before {
  content: @fa-var-vector-square;
}
.@{fa-css-prefix}-venus:before {
  content: @fa-var-venus;
}
.@{fa-css-prefix}-venus-double:before {
  content: @fa-var-venus-double;
}
.@{fa-css-prefix}-venus-mars:before {
  content: @fa-var-venus-mars;
}
.@{fa-css-prefix}-vest:before {
  content: @fa-var-vest;
}
.@{fa-css-prefix}-vest-patches:before {
  content: @fa-var-vest-patches;
}
.@{fa-css-prefix}-viacoin:before {
  content: @fa-var-viacoin;
}
.@{fa-css-prefix}-viadeo:before {
  content: @fa-var-viadeo;
}
.@{fa-css-prefix}-viadeo-square:before {
  content: @fa-var-viadeo-square;
}
.@{fa-css-prefix}-vial:before {
  content: @fa-var-vial;
}
.@{fa-css-prefix}-vials:before {
  content: @fa-var-vials;
}
.@{fa-css-prefix}-viber:before {
  content: @fa-var-viber;
}
.@{fa-css-prefix}-video:before {
  content: @fa-var-video;
}
.@{fa-css-prefix}-video-slash:before {
  content: @fa-var-video-slash;
}
.@{fa-css-prefix}-vihara:before {
  content: @fa-var-vihara;
}
.@{fa-css-prefix}-vimeo:before {
  content: @fa-var-vimeo;
}
.@{fa-css-prefix}-vimeo-square:before {
  content: @fa-var-vimeo-square;
}
.@{fa-css-prefix}-vimeo-v:before {
  content: @fa-var-vimeo-v;
}
.@{fa-css-prefix}-vine:before {
  content: @fa-var-vine;
}
.@{fa-css-prefix}-virus:before {
  content: @fa-var-virus;
}
.@{fa-css-prefix}-virus-slash:before {
  content: @fa-var-virus-slash;
}
.@{fa-css-prefix}-viruses:before {
  content: @fa-var-viruses;
}
.@{fa-css-prefix}-vk:before {
  content: @fa-var-vk;
}
.@{fa-css-prefix}-vnv:before {
  content: @fa-var-vnv;
}
.@{fa-css-prefix}-voicemail:before {
  content: @fa-var-voicemail;
}
.@{fa-css-prefix}-volleyball-ball:before {
  content: @fa-var-volleyball-ball;
}
.@{fa-css-prefix}-volume-down:before {
  content: @fa-var-volume-down;
}
.@{fa-css-prefix}-volume-mute:before {
  content: @fa-var-volume-mute;
}
.@{fa-css-prefix}-volume-off:before {
  content: @fa-var-volume-off;
}
.@{fa-css-prefix}-volume-up:before {
  content: @fa-var-volume-up;
}
.@{fa-css-prefix}-vote-yea:before {
  content: @fa-var-vote-yea;
}
.@{fa-css-prefix}-vr-cardboard:before {
  content: @fa-var-vr-cardboard;
}
.@{fa-css-prefix}-vuejs:before {
  content: @fa-var-vuejs;
}
.@{fa-css-prefix}-walking:before {
  content: @fa-var-walking;
}
.@{fa-css-prefix}-wallet:before {
  content: @fa-var-wallet;
}
.@{fa-css-prefix}-warehouse:before {
  content: @fa-var-warehouse;
}
.@{fa-css-prefix}-watchman-monitoring:before {
  content: @fa-var-watchman-monitoring;
}
.@{fa-css-prefix}-water:before {
  content: @fa-var-water;
}
.@{fa-css-prefix}-wave-square:before {
  content: @fa-var-wave-square;
}
.@{fa-css-prefix}-waze:before {
  content: @fa-var-waze;
}
.@{fa-css-prefix}-weebly:before {
  content: @fa-var-weebly;
}
.@{fa-css-prefix}-weibo:before {
  content: @fa-var-weibo;
}
.@{fa-css-prefix}-weight:before {
  content: @fa-var-weight;
}
.@{fa-css-prefix}-weight-hanging:before {
  content: @fa-var-weight-hanging;
}
.@{fa-css-prefix}-weixin:before {
  content: @fa-var-weixin;
}
.@{fa-css-prefix}-whatsapp:before {
  content: @fa-var-whatsapp;
}
.@{fa-css-prefix}-whatsapp-square:before {
  content: @fa-var-whatsapp-square;
}
.@{fa-css-prefix}-wheelchair:before {
  content: @fa-var-wheelchair;
}
.@{fa-css-prefix}-whmcs:before {
  content: @fa-var-whmcs;
}
.@{fa-css-prefix}-wifi:before {
  content: @fa-var-wifi;
}
.@{fa-css-prefix}-wikipedia-w:before {
  content: @fa-var-wikipedia-w;
}
.@{fa-css-prefix}-wind:before {
  content: @fa-var-wind;
}
.@{fa-css-prefix}-window-close:before {
  content: @fa-var-window-close;
}
.@{fa-css-prefix}-window-maximize:before {
  content: @fa-var-window-maximize;
}
.@{fa-css-prefix}-window-minimize:before {
  content: @fa-var-window-minimize;
}
.@{fa-css-prefix}-window-restore:before {
  content: @fa-var-window-restore;
}
.@{fa-css-prefix}-windows:before {
  content: @fa-var-windows;
}
.@{fa-css-prefix}-wine-bottle:before {
  content: @fa-var-wine-bottle;
}
.@{fa-css-prefix}-wine-glass:before {
  content: @fa-var-wine-glass;
}
.@{fa-css-prefix}-wine-glass-alt:before {
  content: @fa-var-wine-glass-alt;
}
.@{fa-css-prefix}-wix:before {
  content: @fa-var-wix;
}
.@{fa-css-prefix}-wizards-of-the-coast:before {
  content: @fa-var-wizards-of-the-coast;
}
.@{fa-css-prefix}-wodu:before {
  content: @fa-var-wodu;
}
.@{fa-css-prefix}-wolf-pack-battalion:before {
  content: @fa-var-wolf-pack-battalion;
}
.@{fa-css-prefix}-won-sign:before {
  content: @fa-var-won-sign;
}
.@{fa-css-prefix}-wordpress:before {
  content: @fa-var-wordpress;
}
.@{fa-css-prefix}-wordpress-simple:before {
  content: @fa-var-wordpress-simple;
}
.@{fa-css-prefix}-wpbeginner:before {
  content: @fa-var-wpbeginner;
}
.@{fa-css-prefix}-wpexplorer:before {
  content: @fa-var-wpexplorer;
}
.@{fa-css-prefix}-wpforms:before {
  content: @fa-var-wpforms;
}
.@{fa-css-prefix}-wpressr:before {
  content: @fa-var-wpressr;
}
.@{fa-css-prefix}-wrench:before {
  content: @fa-var-wrench;
}
.@{fa-css-prefix}-x-ray:before {
  content: @fa-var-x-ray;
}
.@{fa-css-prefix}-xbox:before {
  content: @fa-var-xbox;
}
.@{fa-css-prefix}-xing:before {
  content: @fa-var-xing;
}
.@{fa-css-prefix}-xing-square:before {
  content: @fa-var-xing-square;
}
.@{fa-css-prefix}-y-combinator:before {
  content: @fa-var-y-combinator;
}
.@{fa-css-prefix}-yahoo:before {
  content: @fa-var-yahoo;
}
.@{fa-css-prefix}-yammer:before {
  content: @fa-var-yammer;
}
.@{fa-css-prefix}-yandex:before {
  content: @fa-var-yandex;
}
.@{fa-css-prefix}-yandex-international:before {
  content: @fa-var-yandex-international;
}
.@{fa-css-prefix}-yarn:before {
  content: @fa-var-yarn;
}
.@{fa-css-prefix}-yelp:before {
  content: @fa-var-yelp;
}
.@{fa-css-prefix}-yen-sign:before {
  content: @fa-var-yen-sign;
}
.@{fa-css-prefix}-yin-yang:before {
  content: @fa-var-yin-yang;
}
.@{fa-css-prefix}-yoast:before {
  content: @fa-var-yoast;
}
.@{fa-css-prefix}-youtube:before {
  content: @fa-var-youtube;
}
.@{fa-css-prefix}-youtube-square:before {
  content: @fa-var-youtube-square;
}
.@{fa-css-prefix}-zhihu:before {
  content: @fa-var-zhihu;
}
